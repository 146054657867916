// ChatWindow.js
import React, { useState, useEffect, useRef } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { Loader2 } from 'lucide-react';

const ChatWindow = ({
  messages,
  inputMessage,
  setInputMessage,
  isGenerating,
  onSendMessage,
  onStopGeneration,
  pastes,
  onDeletePaste,
  onFileUpload,
  onAddPaste,
  listRef,
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental,
  isThreadLoading,
}) => {
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const lastMessageCountRef = useRef(messages.length);

  // Update auto-scroll state when messages change
  useEffect(() => {
    const hasNewMessage = messages.length > lastMessageCountRef.current;
    if (hasNewMessage) {
      setShouldAutoScroll(true);
    }
    lastMessageCountRef.current = messages.length;
  }, [messages.length]);

  const handleSendMessageWrapper = async (message) => {
    setShouldAutoScroll(true);
    await onSendMessage(message);
    
    // Ensure scroll happens after message is sent
    setTimeout(() => {
      if (listRef.current) {
        const container = listRef.current._outerRef;
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'auto'
        });
      }
    }, 100);
  };

  // Create messages array with loading bubble if generating
  const displayMessages = isGenerating
    ? [...messages, { type: 'loading', role: 'assistant' }]
    : messages;

  return (
    <div className="flex flex-col h-full relative">
      {/* Loading overlay */}
      {isThreadLoading && (
        <div className="absolute inset-0 bg-[#1a1a1a] bg-opacity-50 flex items-center justify-center z-50">
          <div className="flex flex-col items-center space-y-4 p-4 rounded-lg bg-[#2a2a2a]">
            <Loader2 className="w-8 h-8 text-orange-500 animate-spin" />
            <p className="text-white text-sm">Loading conversation...</p>
          </div>
        </div>
      )}

      <div className="flex-grow overflow-hidden">
        <MessageList 
          messages={displayMessages}
          shouldAutoScroll={shouldAutoScroll}
          listRef={listRef}
        />
      </div>
      <div className="flex-shrink-0">
        <MessageInput
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          isGenerating={isGenerating}
          onSendMessage={handleSendMessageWrapper}
          onStopGeneration={onStopGeneration}
          pastes={pastes}
          onDeletePaste={onDeletePaste}
          onFileUpload={onFileUpload}
          onAddPaste={onAddPaste}
          currentlySelectedModel={currentlySelectedModel}
          experimentalFeatures={experimentalFeatures}
          onToggleExperimental={onToggleExperimental}
          isDisabled={isThreadLoading}
          disabledMessage="Please wait while the conversation loads or start a new chat."
        />
      </div>
    </div>
  );
};

export default ChatWindow;
