import React from 'react';

const ProjectsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white shadow-lg">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center py-6">
            <div className="text-2xl font-bold text-gray-800">Matt Abrahamson</div>
            <div className="space-x-8">
              <a href="#projects" className="text-gray-600 hover:text-gray-900">Projects</a>
              <a href="#about" className="text-gray-600 hover:text-gray-900">About</a>
              <a href="#contact" className="text-gray-600 hover:text-gray-900">Contact</a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="bg-gradient-to-r from-blue-600 to-indigo-600 py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h1 className="text-5xl font-bold text-white mb-6">
            AI-Driven Solutions
          </h1>
          <p className="text-xl text-gray-200 mb-8">
            Building intelligent systems that solve real-world problems
          </p>
        </div>
      </header>

      {/* Projects Grid */}
      <section id="projects" className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-gray-800 mb-12">Featured Projects</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Project Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">Henosis.us</h3>
                <p className="text-gray-600 mb-4">
                  Full-stack AI platform with multi-provider LLM integration
                </p>
                <div className="flex space-x-2">
                  <span className="bg-blue-100 text-blue-800 text-sm px-2 py-1 rounded">
                    AI/ML
                  </span>
                  <span className="bg-green-100 text-green-800 text-sm px-2 py-1 rounded">
                    Full-stack
                  </span>
                </div>
              </div>
            </div>

            {/* Add more project cards here */}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="bg-white py-20">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">About Me</h2>
          <p className="text-gray-600 leading-relaxed">
            [Your professional summary here]
          </p>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 py-8">
        <div className="max-w-6xl mx-auto px-4 text-center text-gray-400">
          © 2024 Matt Abrahamson. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default ProjectsPage;
    