import React from 'react';

const UnsubscribeModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isUnsubscribing, 
  unsubscribeError 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#2a2a2a] rounded-xl shadow-2xl w-96 p-6 relative">
        {/* Close Button */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-300"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>

        {/* Modal Content */}
        <h2 className="text-xl font-semibold text-white mb-4">
          Cancel Subscription
        </h2>

        <p className="text-gray-400 mb-6">
          Are you sure you want to cancel your subscription at the end of the current billing window? 
          This action cannot be undone.
        </p>

        {/* Error Message */}
        {unsubscribeError && (
          <div className="bg-red-600/10 border border-red-600/20 text-red-400 px-4 py-3 rounded mb-4 text-sm">
            {unsubscribeError}
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex space-x-4">
          <button 
            onClick={onClose}
            className="flex-1 py-2 px-4 bg-[#3a3a3a] text-gray-200 rounded-lg hover:bg-[#404040] transition"
          >
            Keep Subscription
          </button>
          <button 
            onClick={onConfirm}
            disabled={isUnsubscribing}
            className={`
              flex-1 py-2 px-4 bg-red-600/10 text-red-400 rounded-lg 
              hover:bg-red-600/20 transition
              ${isUnsubscribing ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            {isUnsubscribing ? 'Cancelling...' : 'Cancel Subscription'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeModal;
