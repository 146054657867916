// src/hooks/useSettings.js

import { useState, useCallback } from 'react';
import { fetchCustomInstructions, saveCustomInstructions } from '../services/api';

// Define models that support experimental features
export const modelsWithExperimental = [
  { value: 'gpt-4o-mini', label: 'GPT-4O Mini' },
  { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-11-20' },
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash' },
  { value: 'command-r7b-12-2024', label: 'Command R7B 12-2024' },
  { value: 'command-r-plus-08-2024', label: 'Command R Plus 08-2024' },
  { value: 'command-r', label: 'Command R' },
  { value: 'o1', label: 'o1' },
];

// Helper function to check if model supports experimental features
const isModelWithExperimental = (modelId) => {
  if (!modelId) return false;
  return modelsWithExperimental.some(model => model.value === modelId);
};

const useSettings = ({ onLogout, defaultModel }) => {
  const [customInstructions, setCustomInstructions] = useState('');
  const [currentlySelectedModel, setCurrentlySelectedModel] = useState(defaultModel);
  const [experimentalFeatures, setExperimentalFeatures] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [sidebarPosition, setSidebarPosition] = useState('left');
  const handleSettingsToggle = () => setIsSettingsOpen(!isSettingsOpen);

  // Modified to check model support
  const handleExperimentalFeaturesToggle = async () => {
    try {
      // Only allow toggle if model supports experimental features
      if (!isModelWithExperimental(currentlySelectedModel)) {
        return; // Do nothing if model doesn't support experimental
      }

      const newState = !experimentalFeatures;
      setExperimentalFeatures(newState);
      await saveCustomInstructions(
        customInstructions,
        currentlySelectedModel,
        newState,
        sidebarPosition
      );
    } catch (error) {
      console.error('Failed to save experimental features:', error);
      setError('Failed to save settings. Please try again.');
      if (error.message === 'Unauthorized') {
        onLogout();
      }
    }
  };

  // Modified to handle model changes and sidebar position
  const handleSaveSettings = async (newSettings) => {
    try {
      setCustomInstructions(newSettings.customInstructions);
      setCurrentlySelectedModel(newSettings.currentlySelectedModel);
      setSidebarPosition(newSettings.sidebarPosition);
      
      // If switching to a non-supported model, force experimental off
      const supported = isModelWithExperimental(newSettings.currentlySelectedModel);
      const newExperimentalState = supported ? experimentalFeatures : false;
      
      if (!supported) {
        setExperimentalFeatures(false);
      }

      await saveCustomInstructions(
        newSettings.customInstructions,
        newSettings.currentlySelectedModel,
        newExperimentalState,
        newSettings.sidebarPosition
      );

      handleSettingsToggle();
    } catch (error) {
      console.error('Failed to save settings:', error);
      setError('Failed to save settings. Please try again.');
      if (error.message === 'Unauthorized') {
        onLogout();
      }
    }
  };

  // Modified to handle model support and sidebar position on initial load
  const fetchSettings = useCallback(async () => {
    try {
      const data = await fetchCustomInstructions();
      setCustomInstructions(data.instructions || '');
      setCurrentlySelectedModel(data.last_model || defaultModel);
      setSidebarPosition(data.sidebar_position || 'left');
      
      // Only enable experimental if model supports it
      const supported = isModelWithExperimental(data.last_model || defaultModel);
      setExperimentalFeatures(supported ? (data.experimental_features || false) : false);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      setError('Failed to fetch settings. Please try again.');
      if (error.message === 'Unauthorized') {
        onLogout();
      }
    }
  }, [defaultModel, onLogout]);

  return {
    customInstructions,
    setCustomInstructions,
    currentlySelectedModel,
    setCurrentlySelectedModel,
    experimentalFeatures,
    setExperimentalFeatures,
    isSettingsOpen,
    handleSettingsToggle,
    handleSaveSettings,
    handleExperimentalFeaturesToggle,
    fetchSettings,
    error,
    setError,
    isModelWithExperimental,
    sidebarPosition,
    setSidebarPosition,
  };
};

export default useSettings;
