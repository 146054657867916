import React from 'react';
import { User } from 'lucide-react';

const ProfileHeader = ({ username, email, avatar }) => {
  return (
    <div className="relative mb-8 pb-6 border-b border-[#2a2a2a]">
      <div className="flex items-center space-x-6">
        <div className="relative">
          <div className="w-24 h-24 rounded-full bg-gradient-to-br from-orange-500/20 to-[#2a2a2a] p-1 shadow-lg">
            <div className="w-full h-full rounded-full bg-[#1a1a1a] flex items-center justify-center overflow-hidden">
              {avatar ? (
                <img 
                  src={avatar} 
                  alt={username} 
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <span className="text-3xl font-bold text-gray-300">
                    {username.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="flex-grow">
          <div className="flex items-center space-x-3">
            <h1 className="text-3xl font-bold text-gray-300">{username}</h1>
            {/* Optional verification badge */}
            {/* <Shield className="w-5 h-5 text-green-500" /> */}
          </div>
          <p className="text-gray-400 mt-2 flex items-center space-x-2">
            <User className="w-4 h-4 text-gray-500" />
            <span>{email}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
