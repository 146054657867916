import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Share2, Sparkles, AlertOctagon } from 'lucide-react';
import MessageList from './MessageList';
import ErrorPopup from '../Common/ErrorPopup';
import config from '../../config';
import { getSharedThread, saveConversation } from '../../services/api';
import useThreads from '../../hooks/useThreads';

const SharedThreadView = ({ isLoggedIn, subscriptionTier }) => {
  const { threadId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [threadData, setThreadData] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [currentModel, setCurrentModel] = useState('gemini-1.5-flash-latest');
  const [isSharedThreadNotFound, setIsSharedThreadNotFound] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const { handleNewThread } = useThreads({ 
    onLogout: () => {}, 
    defaultModel: currentModel 
  });

  useEffect(() => {
    const loadSharedThread = async () => {
      console.log('Loading shared thread with ID:', threadId);
      
      try {
        console.log('Calling getSharedThread...');
        const data = await getSharedThread(threadId);
        console.log('Raw shared thread response:', data);

        setThreadData(data);
        console.log('ThreadData after setting:', data);

        if (Array.isArray(data.messages)) {
          console.log('Setting messages from data.messages array');
          setMessages(data.messages);
        } else {
          console.log('No messages found in response');
          setMessages([]);
        }

        if (data.selectedModel) {
          console.log('Setting model from thread:', data.selectedModel);
          setCurrentModel(data.selectedModel);
        }
      } catch (error) {
        console.error('Error loading shared thread:', {
          error,
          errorMessage: error.message,
          threadId
        });

        if (error.message.includes('404')) {
          setIsSharedThreadNotFound(true);
          setError('The shared thread you are trying to access does not exist or has been deleted.');
        } else {
          setError(error.message || 'An unexpected error occurred while loading the shared thread');
        }
      }
    };

    loadSharedThread();
  }, [threadId]);

  const createAndNavigateToThread = async (initialMessage = '') => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    console.log('createAndNavigateToThread called with:', {
      threadData,
      messages,
      currentModel,
      initialMessage
    });

    try {
      if (!threadData) {
        console.error('ThreadData is undefined:', {
          stateValues: {
            threadData,
            messages: messages?.length,
            currentModel
          }
        });
        throw new Error('Thread data not available');
      }

      console.log('Thread data available:', threadData);
      const threadName = threadData.title;
      
      if (!threadName) {
        console.error('No title found in thread data:', threadData);
        throw new Error('No title available in thread data');
      }

      console.log('Creating new thread with name:', threadName);

      const response = await fetch(`${config.apiUrl}/create_thread`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          name: threadName,
        }),
      });

      const data = await response.json();
      console.log('Thread creation response:', data);

      if (!data.thread_uid) {
        throw new Error('Failed to get valid thread ID');
      }

      console.log('Saving conversation:', {
        threadId: data.thread_uid,
        threadName,
        messageCount: messages.length,
      });

      await saveConversation(
        threadName,
        messages,
        data.thread_uid,
        currentModel
      );

      navigate(`/chat/${data.thread_uid}`);

    } catch (error) {
      console.error('Thread creation error:', {
        error,
        threadData,
        messages: messages?.length,
        currentModel
      });
      setError('Failed to create new chat thread');
    }
  };

  const handleContinueChat = () => {
    createAndNavigateToThread();
  };

  const handleCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 2000);
  };

  const renderBottomSection = () => {
    if (isLoggedIn) {
      return (
        <div className="bg-[#2a2a2a] rounded-lg p-4 flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-4">
            Welcome Back
          </h3>
          <button
            onClick={handleContinueChat}
            className="px-6 py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-lg transition-colors flex items-center space-x-2"
          >
            <span>Continue this Chat</span>
          </button>
        </div>
      );
    }

    return (
      <div className="bg-[#2a2a2a] rounded-lg p-4 flex flex-col items-center">
        <div className="text-center mb-4">
          <h3 className="text-lg font-semibold">
            Create an account or sign in to continue chatting on Henosis
          </h3>
        </div>
        
        <div className="flex space-x-4 mb-4">
          <Link
            to="/pricing"
            className="px-6 py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-lg transition-colors flex items-center space-x-2"
          >
            <span>Continue this Chat</span>
          </Link>
          
          <Link
            to="/landing"
            className="px-6 py-3 bg-[#3a3a3a] hover:bg-[#4a4a4a] text-white rounded-lg transition-colors flex items-center space-x-2"
          >
            <span>Learn More</span>
          </Link>
        </div>

        <div className="flex flex-row flex-wrap justify-center gap-x-8 gap-y-2 text-sm text-gray-300">
          <div className="flex items-center">
            <span className="w-1.5 h-1.5 bg-orange-500 rounded-full mr-2"></span>
            Access 15+ frontier AI models
          </div>
          <div className="flex items-center">
            <span className="w-1.5 h-1.5 bg-orange-500 rounded-full mr-2"></span>
            Full model context windows
          </div>
          <div className="flex items-center">
            <span className="w-1.5 h-1.5 bg-orange-500 rounded-full mr-2"></span>
            Advanced internet research capabilities
          </div>
        </div>
      </div>
    );
  };

  if (isSharedThreadNotFound) {
    return (
      <div className="flex flex-col h-screen bg-[#1a1a1a] text-white">
        <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 relative">
          <div className="flex items-center">
            <div className="hidden lg:flex items-center cursor-pointer ml-2" onClick={() => (window.location.href = '/')}>
              <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
              <div className="text-lg font-bold">henosis</div>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Link
              to="/pricing"
              className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
              aria-label="Pricing"
              title="Pricing"
            >
              <span className="text-sm">Pricing</span>
            </Link>

            <Link
              to="/landing"
              className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
              aria-label="Features"
              title="Features"
            >
              <Sparkles size={15} />
            </Link>
          </div>
        </header>

        <div className="flex-grow flex items-center justify-center">
          <div className="text-center max-w-md px-4">
            <AlertOctagon size={64} className="mx-auto mb-4 text-red-500" />
            <h1 className="text-2xl font-bold mb-4">Shared Thread Not Found</h1>
            <p className="text-gray-300 mb-6">
              The shared thread you are trying to access does not exist or has been deleted. It may have been removed by
              the original creator or is no longer available.
            </p>
            <div className="flex justify-center space-x-4">
              <Link
                to="/chat"
                className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
              >
                Go to Chat
              </Link>
              <Link
                to="/landing"
                className="px-4 py-2 bg-[#2a2a2a] text-white rounded hover:bg-[#3a3a3a] transition-colors"
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-[#1a1a1a] text-white">
      <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 relative">
        <div className="flex items-center">
          <div className="hidden lg:flex items-center cursor-pointer ml-2" onClick={() => (window.location.href = '/')}>
            <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold">henosis</div>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="relative">
            <button
              onClick={handleCopyShareLink}
              className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
              aria-label="Copy Share Link"
              title="Copy Share Link"
            >
              <Share2 size={15} />
            </button>

            {showCopyNotification && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-green-600 text-white text-sm rounded shadow-lg whitespace-nowrap z-50">
                Copied!
              </div>
            )}
          </div>

          <Link
            to="/pricing"
            className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
            aria-label="Pricing"
            title="Pricing"
          >
            <span className="text-sm">Pricing</span>
          </Link>

          <Link
            to="/landing"
            className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
            aria-label="Features"
            title="Features"
          >
            <span className="text-sm">Features</span>
          </Link>
        </div>
      </header>

      <div className="flex-grow flex justify-center overflow-hidden relative">
        <div className="flex flex-col flex-grow min-h-0 max-w-[1000px] w-full px-2 sm:px-4">
          <MessageList messages={messages} shouldAutoScroll={false} pastes={[]} />
        </div>
      </div>

      <div className="flex-shrink-0 max-w-[1000px] w-full mx-auto px-4 pb-6">
        {renderBottomSection()}
      </div>

      {error && !isSharedThreadNotFound && (
        <ErrorPopup error={error} onClose={() => setError(null)} />
      )}

      {showLoginPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#2a2a2a] rounded-lg p-6 max-w-md w-full mx-4">
            <h2 className="text-xl font-bold mb-4">Sign Up to Continue</h2>
            <p className="text-gray-300 mb-6">
              Henosis provides a revolutionary AI chat experience with powerful features. By creating an account, you'll unlock:
              <ul className="list-disc list-inside mt-2">
                <li>Web search with AI-powered research capabilities</li>
                <li>Multi-modal input (text, images, audio)</li>
                <li>Access to 15+ leading AI models</li>
                <li>Powerful Multi-model workflows with model swapping</li>
                <li>Access to full model context windows</li>
                <li>Unlimited file uploads</li>
              </ul>
            </p>

            <div className="flex justify-between space-x-4">
              <button
                onClick={() => setShowLoginPopup(false)}
                className="px-4 py-2 bg-[#3a3a3a] text-white rounded hover:bg-[#4a4a4a] transition-colors"
              >
                Close
              </button>
              <div className="flex space-x-4">
                <Link
                  to="/pricing"
                  className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
                >
                  Sign Up
                </Link>
                <Link
                  to="/landing"
                  className="px-4 py-2 bg-[#3a3a3a] text-white rounded hover:bg-[#4a4a4a] transition-colors"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharedThreadView;
