import React from 'react';

const ProfileSection = ({ title, children, icon: Icon }) => {
  return (
    <div className="bg-[#1a1a1a] rounded-xl border border-[#2a2a2a] overflow-hidden 
      transform transition-all duration-300 hover:shadow-lg">
      <div className="bg-[#2a2a2a] px-6 py-4 flex items-center space-x-3 border-b border-[#3a3a3a]">
        {Icon && (
          <div className="p-2 bg-orange-500/10 rounded-lg">
            <Icon className="w-5 h-5 text-orange-500" />
          </div>
        )}
        <h3 className="text-xl font-semibold text-gray-300">{title}</h3>
      </div>
      
      <div className="p-6 space-y-4 text-gray-300">
        {children}
      </div>
    </div>
  );
};

export default ProfileSection;
