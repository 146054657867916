// src/components/Landing/LandingPage.js
import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Bot, 
  Shield, 
  Users,
  Brain,
  MessageSquare,
  Image,
  FileAudio,
  Zap,
  ChevronRight,
  Sparkles,
  ArrowRight,
  Star,
  CheckCircle,
  Code,
  Music,
  FolderPlus,
  Lock,
  PieChart,
  Smartphone,
  Globe,
  Smile,
  Layers,
  ArrowLeftRight,
  AlertTriangle,
  Lightbulb,
  Search,
  XCircle,
  FileText,
  Clock,
  AudioLines,
  Play
} from 'lucide-react';
const DocumentAnalysisSection = () => {
  return (
    <section id="document-analysis" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Turn Documents Into Complete Insights
          </h2>
          <p className="text-xl text-gray-400">
            Tired of unreliable document analysis? When AI sees everything, you get actionable insights.
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Analysis</h3>
              </div>
              <span className="text-green-500 text-sm">Full Document Access</span>
            </div>

            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-blue-400" />
                  <span className="text-blue-400">genesis.txt</span>
                </div>
                <div className="text-white">What are the key differences between Chapter 1 and the final chapter of this document?</div>
              </div>

              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300">
                  <p>Analyzing complete document structure:</p>
                  <div className="mt-3 space-y-2">
                    <div className="text-sm">
                      <span className="text-blue-400">Chapter 1:</span>
                      <p>Focuses on creation narrative with structured day-by-day format...</p>
                    </div>
                    <div className="text-sm">
                      <span className="text-blue-400">Final Chapter:</span>
                      <p>Shifts to narrative style describing consequences and outcomes...</p>
                    </div>
                    <div className="mt-4 text-sm">
                      <p>Key Thematic Evolution:</p>
                      <ul className="list-disc pl-4">
                        <li>Opening establishes order and creation</li>
                        <li>Closing addresses human condition</li>
                        <li>Complete narrative arc visible across chapters</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 text-sm text-green-500">
                    Analyzed: Full document content (200K+ tokens)
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Full document access</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Complete context</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Actionable insights</span>
              </div>
            </div>
          </div>

          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-sm">32K Token Limit</span>
            </div>

            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-blue-400" />
                  <span className="text-blue-400">genesis.txt</span>
                </div>
                <div className="text-white">What are the key differences between Chapter 1 and the final chapter of this document?</div>
              </div>

              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300">
                  <p>"I can only access portions of the document that match your query. For a complete chapter comparison, please share relevant sections from both chapters."</p>
                  <div className="mt-3">
                    <div className="text-sm">
                      <p>Limitations of current access:</p>
                      <ul className="list-disc pl-4 text-yellow-500">
                        <li>Content must be specifically provided or searchable</li>
                        <li>Requires multiple queries for comprehensive analysis</li>
                        <li>May miss subtle thematic connections across content</li>
                        <li>Limited to 32K tokens per query, restricting full document analysis</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 text-sm text-yellow-500">
                    Note: RAG retrieves relevant snippets based on query, but cannot analyze the full document.
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Multiple queries needed</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Context must be explicit</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Token limit restricts full analysis</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 bg-[#2a2a2a] p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Why Full Context Matters</h3>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h4 className="font-medium mb-2 text-green-500">With Complete Context</h4>
              <ul className="text-gray-400 space-y-2">
                <li>• Make decisions based on entire documents, not pieces</li>
                <li>• Discover insights that others miss with partial analysis</li>
                <li>• Get answers without back-and-forth queries</li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium mb-2 text-yellow-500">With Limited Context</h4>
              <ul className="text-gray-400 space-y-2">
                <li>• Risk missing critical connections between sections</li>
                <li>• Waste time repeating & modifying queries to get answers</li>
                <li>• Struggle with incomplete understanding</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const ContentAnalysisSection = () => {
  return (
    <section id="content-analysis" className="py-12 md:py-20 px-4 md:px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-3 md:mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Complete Media Understanding
          </h2>
          <p className="text-base md:text-xl text-gray-400">
            Compare how AI assistants analyze long-form audio and video content, 
            from quick summaries to deep insights
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-6 md:gap-12 w-full">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-4 md:p-8 rounded-lg hover:bg-[#333333] transition-all duration-200 w-full max-w-full">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2 flex-shrink-0" />
                <h3 className="font-semibold text-sm md:text-base">Henosis Analysis</h3>
              </div>
              <span className="text-green-500 text-xs md:text-sm whitespace-nowrap">Full Transcript Access</span>
            </div>

            <div className="space-y-4 mb-6 w-full">
              <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-gray-400 flex-shrink-0" />
                  <span className="text-gray-400 text-sm">User Query</span>
                </div>
                <div className="text-white text-sm md:text-base break-words">
                  https://youtu.be/0CBMlzHo0AU summarize this podcast
                </div>
              </div>

              <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                  <span className="text-gray-400 text-sm">Analysis Result</span>
                </div>
                <div className="text-gray-300 space-y-3 text-sm md:text-base">
                  <p className="break-words">The podcast discusses various topics, focusing on the political landscape following the 2024 U.S. presidential elections:</p>
                  
                  <div className="space-y-4 pl-4">
                    <div>
                      <div className="text-blue-400">Host Insights</div>
                      <p className="text-sm break-words">All-in Podcast crew (Jason Calacanis, Chamath Palihapitiya, David Sacks, David Friedberg) discuss topics with insider perspectives. Includes Chamath's global tour experiences.</p>
                    </div>

                    <div>
                      <div className="text-blue-400">Political Impact</div>
                      <p className="text-sm break-words">Analysis of Trump's victory effects on markets, especially crypto and finance sectors, with expected deregulatory policies.</p>
                    </div>

                    <div>
                      <div className="text-blue-400">Cabinet & Policy</div>
                      <p className="text-sm break-words">Detailed discussion of key nominees like Tulsi Gabbard and Matt Gaetz, reflecting strategy to reform government bureaucracy.</p>
                    </div>

                    <div>
                      <div className="text-blue-400">Economic Outlook</div>
                      <p className="text-sm break-words">Coverage of potential IPOs, market adaptations, and broader economic implications under the new administration.</p>
                    </div>

                    <div>
                      <div className="text-blue-400">Industry Analysis</div>
                      <p className="text-sm break-words">Discussion of pharmaceutical advertising influence, prediction markets controversy, and strategies for economic growth.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Complete content understanding</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Detailed context and examples</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Nuanced topic analysis</span>
              </div>
            </div>
          </div>

          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-4 md:p-8 rounded-lg w-full max-w-full">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2 flex-shrink-0" />
                <h3 className="font-semibold text-sm md:text-base">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-xs md:text-sm whitespace-nowrap">Description Only</span>
            </div>

            <div className="space-y-4 mb-6 w-full">
              <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-gray-400 flex-shrink-0" />
                  <span className="text-gray-400 text-sm">User Query</span>
                </div>
                <div className="text-white text-sm md:text-base break-words">
                  https://youtu.be/0CBMlzHo0AU summarize this podcast
                </div>
              </div>

              <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <AlertTriangle className="w-4 h-4 text-yellow-500 flex-shrink-0" />
                  <span className="text-gray-400 text-sm">Analysis Result</span>
                </div>
                <div className="text-gray-300 space-y-3 text-sm md:text-base">
                  <p className="break-words">The podcast episode from the All-In Podcast covers various current events and topics:</p>
                  
                  <div className="space-y-4 pl-4">
                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2 flex-shrink-0" />
                        <span className="text-sm">0:00</span>
                      </div>
                      <p className="text-sm break-words">Bestie Intros: The hosts introduce themselves and set the tone for the episode.</p>
                    </div>

                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2 flex-shrink-0" />
                        <span className="text-sm">7:57</span>
                      </div>
                      <p className="text-sm break-words">Election Impact on Bitcoin, Crypto, and Fintech Stocks: Discussion of recent election results' effects.</p>
                    </div>

                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2 flex-shrink-0" />
                        <span className="text-sm">21:56</span>
                      </div>
                      <p className="text-sm break-words">Mergers & Acquisitions (M&A) and Initial Public Offerings (IPOs) in 2025: Future predictions.</p>
                    </div>

                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2 flex-shrink-0" />
                        <span className="text-sm">39:52</span>
                      </div>
                      <p className="text-sm break-words">Pharma Advertising on Cable TV: Debate on pharmaceutical advertising influence.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Limited to video description</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Surface-level information only</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Missing key context and details</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ModelAvailabilitySection = () => {
  const providers = [
    {
      name: 'Anthropic',
      logo: '/Anthropic.png',
      tagline: 'From Deep Understanding to Leading-Edge Performance',
      models: [
        {
          name: 'Claude 3 Opus',
          speciality: 'Superior contextual understanding and nuanced reasoning',
          details: [
            'Unmatched grasp of subtle, multi-layered complexity',
            'Advanced comprehension of intricate contexts'
          ]
        },
        {
          name: 'Claude 3.5 Sonnet',
          speciality: 'Market-leading coding and technical reasoning',
          details: [
            'Best-in-class coding capabilities',
            'Outperforms Opus in math and technical tasks like coding',
            'Superior reasoning at lower cost',
            'Perfect balance of intelligence and efficiency for enterprise workloads'
          ]
        },
        {
          name: 'Claude 3.5 Haiku',
          speciality: 'Lightning-fast performance with impressive capability',
          details: [
            'Surpasses Claude 3 Opus in technical tasks',
            'Ultra-fast chat responses'
          ]
        }
      ],
      context: 'Up to 200K tokens',
      additionalNote: 'Advanced AI models balancing deep understanding with cutting-edge performance improvements'
    },
    {
      name: 'OpenAI',
      logo: '/openai-2.svg',
      tagline: 'Shaping the Future of AI: Precision, Speed, and Reasoning',
      models: [
        {
          name: 'GPT-4o',
          speciality: 'Multimodal AI That Adapts Across Domains',
          details: [
            'Advanced text, vision, and audio processing',
            'Enhanced creative capabilities'
          ]
        },
        {
          name: 'o1',
          speciality: 'Designed for Depth, Logic, and Reasoning',
          details: [
            'Specialized in complex problem-solving',
            'Scientific and mathematical precision',
            'Unique chain-of-thought processing'
          ]
        },
        {
          name: 'o1 Mini',
          speciality: 'Cost-Efficient AI Without Compromising Brilliance',
          details: [
            'Reduced latency for faster responses',
            'Efficient STEM analysis'
          ]
        }
      ],
      context: 'Up to 200K tokens',
      additionalNote: 'Complete suite of AI models optimized for different use cases, from high-performance computing to efficient, specialized processing'
    },
    {
      name: 'Google',
      logo: '/Gemini2.webp',
      tagline: 'Streamlined Performance Across Tasks',
      models: [
        {
          name: 'Gemini 1.5 Pro',
          speciality: 'Complex reasoning and creative tasks across huge context',
          details: [
            'Massive 2M token context window',
            'Advanced reasoning at unprecedented scale',
            'Superior creative and analytical capabilities'
          ]
        },
        {
          name: 'Gemini Flash',
          speciality: 'Extremely cost-effective for real-time, high-frequency processing',
          details: [
            'Optimized for rapid deployment',
            'High-throughput processing',
            'Enterprise-grade efficiency'
          ]
        },
        {
          name: 'Gemini Flash 8B',
          speciality: 'Near-free, efficient lightweight processing for rapid responses',
          details: [
            'Ultra-lightweight deployment',
            'Minimal latency for real-time apps',
            'Exceptional cost efficiency'
          ]
        }
      ],
      context: 'Up to 2M tokens',
      additionalNote: 'Engineered for efficiency across diverse computational challenges'
    },
    {
      "name": "Reka",
      "logo": "/reka_ai_logo.jfif",
      "tagline": "Expert AI for Targeted Expertise",
      "models": [
          {
              "name": "Core",
              "speciality": "Comprehensive reasoning capabilities",
              "details": [
                  "Balanced performance profile offering flexible solutions across a wide variety of scenarios, ensuring effective outcomes regardless of complexity",
                  "Versatile problem-solving abilities for tackling both structured and unstructured challenges with equal proficiency",
                  "Specialized for image understanding workflows & multimodal with an encoder-decoder architecture",
                  "Only 67B total parameters!"
              ]
          },
          {
              "name": "Flash",
              "speciality": "Rapid processing for dynamic environments",
              "details": [
                  "Provides real-time adaptive responses for fast-paced, ever-changing operational demands",
                  "Only 21B total parameters!"
              ]
          },
          {
              "name": "Edge",
              "speciality": "High-precision specialized tasks",
              "details": [
                  "Equipped for advanced domain-specific processing, ensuring accuracy in lightweight applications",
              ]
          }
      ],
      "context": "Up to 128K tokens",
      "additionalNote": "Specialized AI solutions tailored for precise, domain-specific challenges"
  },
    {
      name: 'Cohere',
      logo: '/cohere.png',
      tagline: 'Masters of Retrieval and Document Intelligence',
      models: [
        {
          name: 'Command-R',
          speciality: 'Advanced RAG and document processing',
          details: [
            'Superior retrieval-augmented generation',
            'Optimized for enterprise document analysis'
          ]
        },
        {
          name: 'Command-R-Plus',
          speciality: 'Enhanced context understanding with RAG',
          details: [
            'Improved document comprehension',
            'Advanced information synthesis'
          ]
        },
        {
          name: 'Command-R7B',
          speciality: 'Efficient lightweight RAG processing',
          details: [
            'Fast document processing',
            'Cost-effective information retrieval'
          ]
        }
      ],
      context: 'Up to 128K tokens',
      additionalNote: 'Specialized in RAG technology and document intelligence processing'
    },
    {
      name: 'Deepseek',
      logo: '/deepseek.png',
      tagline: 'Open-Source AI for Coding, Reasoning, and Efficiency',
      models: [
        {
          name: 'Deepseek Chat v3',
          speciality: 'Cost-efficient coding, debugging, and technical reasoning',
          details: [
            'Open-source model with full transparency and community-driven improvements',
             'Total Parameter Count: 671 billion parameters with an active parameter count: 37 billion',
            'Optimized for rapid, cost-effective technical problem-solving',
             "Nearly matches Claude 3.5 Sonnet's breakthrough perforamnce in coding",
            'Is 90% cheaper than Claude 3.5 Sonnet',
          ]
        },
        {
          name: 'Deepseek Reasoner',
          speciality: 'Enhanced reasoning with extended token context',
          details: [
            'Based on Chat v3 architecture',
            'Improves response quality with deeper reasoning',
            '95% cheaper than OpenAI o1',
          ]
        }
      ],
      context: 'Up to 64K tokens',
      additionalNote: 'Deepseek Chat v3 combines open-source transparency with cutting-edge performance for coding, debugging, and technical reasoning tasks. '
    }
  ];

  return (
    <section id="models" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Access Top AI Models
          </h2>
          <p className="text-xl text-gray-400">
            Choose from leading AI models with their full context windows - no artificial limits
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          {providers.map((provider) => (
            <div
              key={provider.name}
              className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200"
            >
              <div className="flex items-center space-x-3 mb-4">
                <img
                  src={provider.logo}
                  alt={`${provider.name} Logo`}
                  className="w-8 h-8 object-contain"
                />
                <h3 className="text-xl font-semibold">{provider.name}</h3>
              </div>

              <div className="space-y-3">
                <div className="text-gray-400">{provider.tagline}</div>
                <div className="space-y-4">
                  {provider.models.map((model) => (
                    <div key={model.name} className="text-sm">
                      <div className="text-gray-300 font-medium">{model.name}</div>
                      <div className="text-gray-500">{model.speciality}</div>
                      {model.details && (
                        <ul className="mt-1 space-y-1">
                          {model.details.map((detail, index) => (
                            <li key={index} className="text-gray-400 text-xs flex items-start">
                              <span className="text-orange-500 mr-2">•</span>
                              {detail}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
                <div className="text-orange-500 font-medium mt-2">{provider.context}</div>
                <div className="text-gray-400 text-xs mt-2">{provider.additionalNote}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-12 bg-[#2a2a2a] p-8 rounded-lg">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <ArrowLeftRight className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Switch Models Instantly</h3>
              <p className="text-sm text-gray-400">
                Change models mid-conversation while maintaining available context
              </p>
            </div>
            <div className="text-center">
              <Lock className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2">No API Keys Needed</h3>
              <p className="text-sm text-gray-400">
                Direct access to all models without setup or configuration
              </p>
            </div>
            <div className="text-center">
              <Layers className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2">Maximum Context Windows</h3>
              <p className="text-sm text-gray-400">
                Use each model's full context capacity without restrictions
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};



// Define this component before the LandingPage component
const SearchComparisonSection = () => {
  return (
    <section id="search-comparison" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Search Results That Actually Help
          </h2>
          <p className="text-xl text-gray-400">
            Compare how different AI search assistants handle the same query
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Search</h3>
              </div>
              <span className="text-green-500 text-sm">Curated, Useful Results</span>
            </div>

            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <Search className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">trapping with my dad lyrics</div>
              </div>

              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-400">Response</span>
                </div>
                <div className="text-gray-300">
                  <p>
                    Here are some sources where you can find the lyrics to "Trapping With My Dad" by Akira The Don:
                  </p>
                </div>
                <div className="space-y-2 pl-4">
                  <div>
                    <div className="text-blue-400 hover:underline cursor-pointer">Genius</div>
                    <div className="text-sm">Full lyrics with annotations explaining lines and themes</div>
                  </div>
                  <div>
                    <div className="text-blue-400 hover:underline cursor-pointer">JioSaavn</div>
                    <div className="text-sm">Listen to the song and read the lyrics</div>
                  </div>
                  <div>
                    <div className="text-blue-400 hover:underline cursor-pointer">Meaningwave</div>
                    <div className="text-sm">Lyrics with song background information</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sources Section for Henosis */}
            <div className="bg-[#1a1a1a] p-4 rounded mb-6">
              <div className="flex items-center space-x-2 mb-2">
                <CheckCircle className="w-4 h-4 text-green-500" />
                <span className="text-gray-400">Sources</span>
              </div>
              <div className="text-gray-300">
                <p>Henosis evaluates 10-20 relevant links and selects high-quality sources & reads them before using them to ensure trustworthy results.</p>
              </div>
            </div>

            {/* Additional Advantages */}
            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Trusted, direct links</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Uncensored Search</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Context for each source</span>
              </div>
            </div>
          </div>

          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-sm">Limited Results</span>
            </div>

            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <Search className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">trapping with my dad lyrics</div>
              </div>

              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-400">Response</span>
                </div>
                <div className="text-gray-300 space-y-3">
                  <p>"I apologize, but I cannot provide or reproduce the lyrics..."</p>
                  <ul className="space-y-1 pl-4">
                    <li>• Listen on Spotify</li>
                    <li>• Purchase through music platforms</li>
                    <li>• Check official lyric websites</li>
                  </ul>
                  <p className="text-sm">Song length: 6:07, Album: "JBPWAVE: FATHER2019"</p>
                </div>
              </div>

              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <XCircle className="w-4 h-4 text-red-500" />
                  <span className="text-gray-400">Sources Found (4)</span>
                </div>
                <div className="text-gray-300 text-sm">
                  <div className="space-y-1">
                    <div>• Luke Combs song (Unrelated)</div>
                    <div>• Reddit JuiceWRLD thread (Unrelated)</div>
                    <div>• Spotify link (No content)</div>
                    <div>• Duplicate Spotify link</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Only reads snippets of source material</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Irrelevant search results, source material isn't chosen by agent</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Generic suggestions only</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const Hero = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleEmailSignup = (e) => {
    e.preventDefault();
    if (email) {
      navigate('/register', { state: { prefillEmail: email } });
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navHeight = 80;
      const sectionTop = section.offsetTop - navHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <header className="relative py-20 px-6">
      <div className="max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-12 items-start mb-16">
          {/* Left Column - Main Content */}
          <div className="text-left">
            <h1 className="text-5xl font-bold mb-6">
              <span className="bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent"> 
              Turn Complex Work Into Solutions
              </span>
            </h1>
            
            <p className="text-xl text-gray-400 mb-12">
              AI chat without the handcuffs - no daily chat limits, full model context, better results
            </p>

            {/* Feature Quick Links */}
            <div className="space-y-4">
              <div 
                onClick={() => scrollToSection('models')}
                className="flex items-center space-x-2 cursor-pointer group"
              >
                <Brain className="text-orange-500" />
                <div className="text-gray-300 group-hover:text-white transition-colors">
                  Access Frontier AI Models All in One Chat
                  <span className="block text-sm text-gray-400">
                    Claude 3.5, GPT-4o, o1, Gemini 1.5, Reka Core and more →
                  </span>
                </div>
              </div>

              <div 
                onClick={() => scrollToSection('code-context')}
                className="flex items-center space-x-2 cursor-pointer group"
              >
                <Code className="text-orange-500" />
                <div className="text-gray-300 group-hover:text-white transition-colors">
                  Accelerate Development From Idea To Launch →
                  <span className="block text-sm text-gray-400">
                    Unlike other AI tools that only see pieces of your content to save costs, Henosis analyzes everything you share - every line, every file →
                  </span>
                </div>
              </div>
              
              <div 
                onClick={() => scrollToSection('search-comparison')}
                className="flex items-center space-x-2 cursor-pointer group"
              >
                <Search className="text-orange-500" />
                <div className="text-gray-300 group-hover:text-white transition-colors">
                  Save Hours of Research Time
                  <span className="block text-sm text-gray-400">
                    Stop switching between AI chats and Google - get informed answers with optional source verification in one place →
                  </span>
                </div>
              </div>
              <div 
                onClick={() => scrollToSection('document-analysis')}
                className="flex items-center space-x-2 cursor-pointer group"
              >
                <ArrowLeftRight className="text-orange-500" />
                <div className="text-gray-300 group-hover:text-white transition-colors">
                  Turn Documents Into Real Insights Instantly
                  <span className="block text-sm text-gray-400">
                    Let AI read entire documents, not just snippets - get deeper insights by analyzing complete context and relationships →
                  </span>
                </div>
              </div>
              <div 
                onClick={() => scrollToSection('content-analysis')}
                className="flex items-center space-x-2 cursor-pointer group"
              >
                <FileText className="text-orange-500" />
                <div className="text-gray-300 group-hover:text-white transition-colors">
                  Turn Long Content Into Quick Insights with Full Comprehension
                  <span className="block text-sm text-gray-400">
                    Process entire podcasts, and videos in minutes - get timestamps, highlights, and topic breakdowns without watching every second →
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Email Signup & Features */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg border border-[#3a3a3a]">
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Get Started with Henosis</h3>
              <p className="text-gray-400 mb-6">
                Access the full capabilities of top AI models without artificial limits
              </p>
              
              {/* Email Signup Form */}
              <form onSubmit={handleEmailSignup} className="space-y-4">
                <input 
                  type="email" 
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-6 py-4 bg-[#1a1a1a] rounded-lg 
                           border border-[#3a3a3a] focus:border-orange-500 
                           focus:outline-none"
                  required
                />
                <button 
                  type="submit"
                  className="w-full px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 
                           hover:from-red-500 hover:to-orange-600 rounded-lg 
                           transition-all duration-200 text-lg font-medium
                           shadow-lg shadow-orange-500/20"
                >
                  Get Started
                </button>
              </form>
            </div>

            {/* Key Features */}
            <div className="space-y-3 border-t border-[#3a3a3a] pt-6">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>Maximum model context windows</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>15+ leading AI models</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>No API keys needed</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>Files up to 100MB</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const AudioAnalysisSection = () => {
  return (
    <section id="audio-analysis" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Process Hours of Audio with Speaker Detection
          </h2>
          <p className="text-xl text-gray-400">
            Upload audio files up to 9.5 hours and get structured transcripts with speaker identification
          </p>
        </div>

        <div className="bg-[#2a2a2a] p-8 rounded-lg">
          {/* Audio Player + Waveform */}
          <div className="mb-8">
            <div className="flex items-center space-x-4 mb-4">
              <button className="p-3 bg-orange-500 rounded-full">
                <Play className="w-6 h-6" />
              </button>
              <div className="flex-1">
                <div className="h-12 bg-[#1a1a1a] rounded-lg overflow-hidden">
                  {/* Animated waveform would go here */}
                  <div className="h-full w-1/3 bg-gradient-to-r from-orange-500 to-red-500 opacity-50" />
                </div>
              </div>
              <span className="text-gray-400">12:34 / 45:21</span>
            </div>
          </div>

          {/* Transcript Section */}
          <div className="space-y-6">
            {/* Speaker 1 */}
            <div className="flex space-x-4">
              <div className="w-24 flex-shrink-0">
                <div className="text-orange-500 font-medium">Speaker 1</div>
                <div className="text-sm text-gray-400">00:00</div>
              </div>
              <p className="text-gray-300">
                Today we're discussing the impact of artificial intelligence on modern software development...
              </p>
            </div>

            {/* Speaker 2 */}
            <div className="flex space-x-4">
              <div className="w-24 flex-shrink-0">
                <div className="text-blue-500 font-medium">Speaker 2</div>
                <div className="text-sm text-gray-400">00:15</div>
              </div>
              <p className="text-gray-300">
                That's interesting, especially considering the recent developments in large language models...
              </p>
            </div>

            {/* Speaker 1 */}
            <div className="flex space-x-4">
              <div className="w-24 flex-shrink-0">
                <div className="text-orange-500 font-medium">Speaker 1</div>
                <div className="text-sm text-gray-400">00:32</div>
              </div>
              <p className="text-gray-300">
                Exactly, and when we look at the productivity gains...
              </p>
            </div>
          </div>

          {/* Features Callout */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <Clock className="w-6 h-6 text-orange-500 mb-2" />
              <h3 className="font-medium mb-1">Process Long Audio</h3>
              <p className="text-sm text-gray-400">Handle files up to 9.5 hours long</p>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <Users className="w-6 h-6 text-orange-500 mb-2" />
              <h3 className="font-medium mb-1">Speaker Detection</h3>
              <p className="text-sm text-gray-400">Automatically identify different speakers</p>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <FileText className="w-6 h-6 text-orange-500 mb-2" />
              <h3 className="font-medium mb-1">Full Transcript</h3>
              <p className="text-sm text-gray-400">Full text transcript</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

/**
 * LandingPage Component
 * Main landing page showcasing Henosis Chat platform features and benefits
 */
const LandingPage = () => {
  const navigate = useNavigate();
  const [expandedFaqs, setExpandedFaqs] = useState(new Set());

  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };
  // Features
  const features = [
    {
      icon: <Bot className="w-6 h-6 text-orange-500" />,
      title: "Multiple AI Models",
      description: "Instant access to Claude 3, GPT-4, Gemini 1.5, Reka, and DeepSeek" 
    },
    {
      icon: <ArrowLeftRight className="w-6 h-6 text-purple-500" />,
      title: "Seamless Model Switching",
      description: "Switch between models during conversations without losing context"
    },
    {
      icon: <Image className="w-6 h-6 text-green-500" />,
      title: "Image Analysis",
      description: "Understand and process images with compatible models"
    },
    {
      icon: <Music className="w-6 h-6 text-blue-500" />,
      title: "Audio Processing 🚧",
      description: "Process audio files up to 9.5 hours with speaker diarization 🏗️ (Coming Soon! 🔜)"
    },
    {
      icon: <Layers className="w-6 h-6 text-yellow-500" />,
      title: "Multi-Format Support",
      description: "Handle images, audio, and documents directly in chat"
    },
    {
      icon: <FolderPlus className="w-6 h-6 text-red-500" />,
      title: "Project-Based Workspaces 🚧", 
      description: "Organize your work with custom threads and project spaces 🏗️ (Coming Soon! 🔜)"
    },
    {
      icon: <Users className="w-6 h-6 text-teal-500" />,
      title: "Team Collaboration",
      description: "Shared projects and scalable team access with usage monitoring"
    },
    {
      icon: <Lock className="w-6 h-6 text-indigo-500" />,
      title: "Data Control",
      description: "Your data stays yours - we don't use your data to train LLMs"
    },    
    {
      icon: <PieChart className="w-6 h-6 text-pink-500" />,
      title: "Usage Analytics",
      description: "Real-time credit updates and detailed usage statistics"
    },
    {
      icon: <Smartphone className="w-6 h-6 text-lime-500" />,
      title: "Responsive Design",
      description: "Optimized for both mobile and desktop experiences"
    },
    {
      icon: <Globe className="w-6 h-6 text-orange-500" />,
      title: "Web Search & Image Generation",
      description: "Enhanced capabilities with GPT models for search and image creation"
    },
    {
      icon: <Smile className="w-6 h-6 text-purple-500" />,
      title: "Custom Instructions",
      description: "Set custom instructions per conversation for personalized interactions"
    }
  ];

  // Testimonials
  const testimonials = [
    {
      name: "Alex P.",
      feedback: "Henosis Chat has transformed how I code. The unlimited context is a game-changer!",
      avatar: "/avatar1.jpg"
    },
    {
      name: "Samantha R.",
      feedback: "Switching to Henosis was the best decision. No more context limitations.",
      avatar: "/avatar2.jpg"
    }
  ];
  const CodeComparisonSection = () => {
    // Full Henosis code example
    const henosisCode = `  
    <ParticleTypes.ts>
    export type ParticleType = 'spark' | 'smoke' | 'fire' | 'explosion';

    export interface ParticleConfig {
      emitRate: number;
      particleLife: number;
      particleDrag: number;
      particleType: ParticleType;
      initialSpeed: number;
      spreadAngle: number;
    }

    export interface Particle {
      position: Vector3;
      velocity: Vector3;
      lifetime: number;
      drag: number;
      type: ParticleType;
    }
    </ParticleTypes.ts>

    <Vector3.ts>
    export class Vector3 {
      constructor(
        public x: number = 0,
        public y: number = 0,
        public z: number = 0
      ) {}

      add(v: Vector3): Vector3 {
        this.x += v.x;
        this.y += v.y;
        this.z += v.z;
        return this;
      }

      multiply(scalar: number): Vector3 {
        this.x *= scalar;
        this.y *= scalar;
        this.z *= scalar;
        return this;
      }

      clone(): Vector3 {
        return new Vector3(this.x, this.y, this.z);
      }

      normalize(): Vector3 {
        const length = Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
        if (length > 0) {
          this.x /= length;
          this.y /= length;
          this.z /= length;
        }
        return this;
      }
    }
    </Vector3.ts>

    <PhysicsEngine.ts>
    export class PhysicsEngine {
      private gravity: Vector3 = new Vector3(0, -9.81, 0);
      private wind: Vector3 = new Vector3();

      setWind(direction: Vector3, strength: number): void {
        this.wind = direction.clone().normalize().multiply(strength);
      }

      applyForces(particle: Particle): void {
        // Apply gravity
        const gravityForce = this.gravity.clone().multiply(deltaTime);
        particle.velocity.add(gravityForce);

        // Apply wind based on particle type
        if (particle.type === 'smoke' || particle.type === 'fire') {
          const windForce = this.wind.clone().multiply(deltaTime);
          particle.velocity.add(windForce);
        }
      }
    }
    </PhysicsEngine.ts>

    <ParticleEmitter.ts>
    export class ParticleEmitter {
      private particles: Particle[] = [];
      private position: Vector3;
      private config: ParticleConfig;
      private age: number = 0;

      constructor(position: Vector3, config: ParticleConfig) {
        this.position = position;
        this.config = config;
      }

      emit(deltaTime: number): void {
        // Remove dead particles
        this.particles = this.particles.filter(p => p.lifetime > 0);
        
        // Emit new particles
        const emitCount = Math.floor(this.config.emitRate * deltaTime);
        for (let i = 0; i < emitCount; i++) {
          this.particles.push(this.createParticle());
        }

        // Update existing particles
        for (const particle of this.particles) {
          particle.lifetime -= deltaTime;
        }
      }

      getParticles(): Particle[] {
        return this.particles;
      }

      private createParticle(): Particle {
        const velocity = this.getRandomVelocity();
        return {
          position: this.position.clone(),
          velocity,
          lifetime: this.config.particleLife,
          drag: this.config.particleDrag,
          type: this.config.particleType
        };
      }

      private getRandomVelocity(): Vector3 {
        const angle = Math.random() * this.config.spreadAngle;
        const direction = new Vector3(
          Math.sin(angle),
          Math.cos(angle),
          0
        ).normalize();
        return direction.multiply(this.config.initialSpeed);
      }
    }
    </ParticleEmitter.ts>

    <ParticleSystem.ts>
    export class ParticleSystem {
      private emitters: ParticleEmitter[] = [];
      private physics: PhysicsEngine;

      constructor(physics: PhysicsEngine) {
        this.physics = physics;
      }

      addEmitter(position: Vector3, config: ParticleConfig): ParticleEmitter {
        const emitter = new ParticleEmitter(position, config);
        this.emitters.push(emitter);
        return emitter;
      }

      update(deltaTime: number): void {
        for (const emitter of this.emitters) {
          // Update particle positions with physics
          const particles = emitter.getParticles();
          for (const particle of particles) {
            // Apply physics forces
            this.physics.applyForces(particle);
            // Update position
            particle.position.add(
              particle.velocity.multiply(deltaTime)
            );
            // Apply damping
            particle.velocity.multiply(1 - particle.drag);
          }
          // Emit new particles
          emitter.emit(deltaTime);
        }
      }
    }
    </ParticleSystem.ts>`;
  
    // Cursor code example
    const cursorCode = `// Currently open file:
  // ParticleSystem.ts
  export class ParticleSystem {
      private emitters: ParticleEmitter[] = [];
      private physics: PhysicsEngine;
  
      constructor(physics: PhysicsEngine) {
          this.physics = physics;
      }
  
      update(deltaTime: number): void {
          for (const emitter of this.emitters) {
              const particles = emitter.getParticles();
              for (const particle of particles) {
                  this.physics.applyForces(particle);
                  particle.position.add(
                      particle.velocity.multiply(deltaTime)
                  );
                  particle.velocity.multiply(1 - particle.drag);
              }
              emitter.emit(deltaTime);
          }
      }
  }
  
  // Potentially relevant code snippets from codebase:
  
  // From ParticleEmitter.ts:
  export class ParticleEmitter {
      emit(deltaTime: number): void {
          const emitCount = this.config.emitRate * deltaTime;
          for (let i = 0; i < emitCount; i++) {
              const particle = this.createParticle();
              this.particles.push(particle);
          }
      }
  }
  
  // From PhysicsEngine.ts:
  export class PhysicsEngine {
      applyForces(particle: Particle): void {
          const gravityForce = this.gravity.clone().multiply(deltaTime);
          particle.velocity.add(gravityForce);
      }
  }
  
  // From Vector3.ts:
  export class Vector3 {
      multiply(scalar: number): Vector3 {
          this.x *= scalar;
          this.y *= scalar;
          this.z *= scalar;
          return this;
      }
  }`;
  
  return (
    <section id="code-context" className="py-12 md:py-20 px-4 md:px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-3 md:mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Complete vs Limited Context
          </h2>
          <p className="text-base md:text-xl text-gray-400">
            While other AI tools cut corners by only seeing pieces of your code, 
            Henosis analyzes every file and dependency you share
          </p>
        </div>
  
        {/* Comparison Grid */}
        <div className="grid lg:grid-cols-2 gap-6 md:gap-12">
          {/* Henosis Side */}
          <div className="bg-[#2a2a2a] p-4 md:p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2" />
                <h3 className="font-semibold text-sm md:text-base">Henosis Chat</h3>
              </div>
              <span className="text-green-500 text-xs md:text-sm">Complete Context</span>
            </div>
  
            <div className="mb-4 md:mb-6">
              <h4 className="text-base md:text-lg font-medium mb-2">What the AI Sees:</h4>
              <ul className="text-gray-400 space-y-1 md:space-y-2 list-disc pl-5 text-sm md:text-base">
                <li>Your exact input, unmodified</li>
                <li>Full conversation history</li>
                <li>All shared code and examples</li>
                <li>Complete implementation details</li>
              </ul>
            </div>
  
            {/* Updated code container */}
            <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
              <div className="flex items-center justify-between mb-2">
                <Code className="w-4 h-4 md:w-5 md:h-5 text-green-500 flex-shrink-0" />
                <span className="text-xs text-gray-500">Complete Context As Shared</span>
              </div>
              <div className="h-[400px] md:h-[600px] relative">
                <div className="absolute inset-0">
                  <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                      <pre className="text-xs md:text-sm font-mono p-2 min-w-max">
                        <code>
                          {henosisCode}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="mt-4 md:mt-6 space-y-2 md:space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Sees complete implementation details</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Understands full system relationships</span>
              </div>
            </div>
          </div>
  
          {/* Cursor Side */}
          <div className="bg-[#2a2a2a] p-4 md:p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/cursor_logo.jpg" alt="Cursor Logo" className="h-5 w-5 md:h-6 md:w-6 mr-2" />
                <h3 className="font-semibold text-sm md:text-base">Cursor</h3>
              </div>
              <span className="text-yellow-500 text-xs md:text-sm">Current File Context</span>
            </div>
  
            <div className="mb-4 md:mb-6">
              <h4 className="text-base md:text-lg font-medium mb-2">What the AI Sees:</h4>
              <ul className="text-gray-400 space-y-1 md:space-y-2 list-disc pl-5 text-sm md:text-base">
                <li>Currently open file contents</li>
                <li>Related code snippets from codebase</li>
                <li>Visible implementation details</li>
                <li>Current file dependencies</li>
              </ul>
            </div>
  
            {/* Updated code container */}
            <div className="bg-[#1a1a1a] p-3 md:p-4 rounded">
              <div className="flex items-center justify-between mb-2">
                <Code className="w-4 h-4 md:w-5 md:h-5 text-yellow-500 flex-shrink-0" />
                <span className="text-xs text-gray-500">Current File + Relevant Snippets</span>
              </div>
              <div className="h-[400px] md:h-[600px] relative">
                <div className="absolute inset-0">
                  <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                      <pre className="text-xs md:text-sm font-mono p-2">
                        {cursorCode}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="mt-4 md:mt-6 space-y-2 md:space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Context will be reduced</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-4 h-4 md:w-5 md:h-5 mr-2 flex-shrink-0" />
                <span className="text-sm md:text-base">Details may be lost in optimization</span>
              </div>
            </div>
          </div>
        </div>
  
        {/* Impact Summary */}
        <div className="mt-8 md:mt-12 bg-[#2a2a2a] p-4 md:p-6 rounded-lg">
          <h3 className="text-lg md:text-xl font-semibold mb-4">Why This Matters</h3>
          <div className="grid md:grid-cols-2 gap-4 md:gap-6">
            <div>
              <h4 className="font-medium mb-2 text-green-500">With Henosis</h4>
              <p className="text-sm md:text-base text-gray-400">
                Get solutions based on your exact input and context.
                The AI works with precisely what you share, just like a colleague would.
              </p>
            </div>
            <div>
              <h4 className="font-medium mb-2 text-yellow-500">With Cursor</h4>
              <p className="text-sm md:text-base text-gray-400">
                Solutions vary as the AI works with currently visible file and snippets,
                requiring additional context-setting by the user.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
  
  // FAQs
  // FAQs
  const faqs = [
    {
      id: 1,
      question: "How quickly can I get started?",
      answer: "Start chatting instantly after a 30-second signup. No API keys or complex setup required - just choose your model and begin. While you can start with simple queries, Henosis includes powerful features like custom AI instructions and project workspaces to help organize your work as you scale."
    },
    {
      id: 2,
      question: "How does Henosis compare to alternatives?",
      answer: "Unlike other tools that use RAG to reduce costs by showing AI only small pieces of context, Henosis provides models complete access to your entire codebase and conversation history up to their maximum context lengths. This means you get solutions based on full understanding, not partial context - especially critical for large projects and complex debugging."
    },
    {
      id: 3,
      question: "What are the available pricing tiers?",
      answer: `We offer flexible tiers to match your needs:<br/>
      • Personal ($10/mo): Standard usage limits<br/>
      • Personal+ ($40/mo): 4x usage limits, all features<br/>
      • Pro ($90/mo): 8x usage limits, priority support<br/>
      • Team ($200/mo): 20x usage, 15 team members<br/>
      • Team+ ($400/mo): 40x usage, 50 team members<br/>
      • Enterprise: Custom solutions<br/>
      Save 20% with annual billing. <a href="/pricing" class="text-orange-500 hover:text-orange-400 transition-colors">View detailed pricing →</a>`
    },
    {
      id: 4,
      question: "How do model usage limits work?",
       answer: `Your monthly credits can be used flexibly across models. For example, with the Personal plan:<br/>
      • Efficient models (Gemini Flash): ~190M tokens<br/>
      • Advanced models (Claude 3.5 Sonnet): ~4.8M tokens<br/>
      Higher tiers multiply these base limits:<br/>
      • Personal+: 4x<br/>
      • Pro: 8x<br/>
      • Team: 20x<br/>
      • Team+: 40x<br/>
       Additional credits can soon be purchased in addition to your plan. They have a 60 day expiration date and require an active subscription to use. Each model has provider-specific context limits (e.g., Claude 3 Opus: 200K tokens, GPT-4: 128K tokens).`
    },
    {
      id: 5,
      question: "What tasks is Henosis best for?",
      answer: `Henosis excels at:<br/>
      • Code Analysis: Full codebase review and debugging<br/>
      • AI Chat: Multi-model conversations<br/>
      • Project Organization: Separate workspaces<br/>
      • Team Collaboration: Shared context (Team+)<br/>
      • Model Comparison: Real-time model switching<br/>
      • Web Search: Enhanced GPT capabilities<br/>
      • Image Generation: AI image creation<br/>
      Future updates will expand these capabilities further.`
    },
    {
      id: 6,
      question: "How does project organization work?",
      answer: `Key project features:<br/>
      • Separate workspaces for different work streams<br/>
      • Seamless model switching<br/>
      • Custom instructions per project<br/>
      • Team sharing (Team tier+)<br/>
      • Usage analytics<br/>`
    }
  ];

  const Navbar = () => {
    const navigate = useNavigate();
    
    return (
      <nav className="sticky top-0 z-50 flex justify-between items-center p-4 md:p-6 
                      bg-[#1a1a1a] border-b border-[#2a2a2a]">
        <div className="flex items-center">
          <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 md:h-8 md:w-8 mr-2" />
          <span className="text-lg md:text-xl font-bold">henosis</span>
        </div>
        
        {/* Mobile-friendly navigation */}
        <div className="flex items-center space-x-3 md:space-x-6">
          <button 
            onClick={() => navigate('/pricing')}
            className="text-sm md:text-base text-gray-300 hover:text-white transition-colors"
          >
            Pricing
          </button>
          <button 
            onClick={() => navigate('/login')}
            className="text-sm md:text-base text-gray-300 hover:text-white transition-colors"
          >
            Login
          </button>
          <button 
            onClick={() => navigate('/register')}
            className="px-3 md:px-4 py-2 text-sm md:text-base bg-gradient-to-r 
                     from-orange-500 to-red-500 hover:from-red-500 hover:to-orange-600 
                     rounded-lg transition-all duration-200"
          >
            Get Started
          </button>
        </div>
      </nav>
    );
  };
  return (
    <div className="min-h-screen bg-[#1a1a1a] text-white">
      <Navbar/>
<Hero/>

      {/* Social Proof - Commented out for now */}
      {/* <section className="py-12 bg-[#1a1a1a]">
        <div className="max-w-6xl mx-auto text-center">
          <p className="text-gray-500 mb-8">TRUSTED BY PROFESSIONALS AT</p>
          <div className="flex justify-center items-center space-x-8 opacity-70">
            {/* Replace with actual partner logos 
            <img src="/partner1.png" alt="Partner 1" className="h-12" />
            <img src="/partner2.png" alt="Partner 2" className="h-12" />
            <img src="/partner3.png" alt="Partner 3" className="h-12" />
          </div>
        </div>
      </section> */}
<CodeComparisonSection/>
<SearchComparisonSection />
<ModelAvailabilitySection /> 
<DocumentAnalysisSection/>
<ContentAnalysisSection />
{/* <AudioAnalysisSection/> */}
      {/* Features Section */}
      <section className="py-20 px-6 bg-[#1a1a1a]">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Core AI Features</h2>
            <p className="text-gray-400">Everything you need for advanced AI interactions</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index} 
                className="bg-[#2a2a2a] p-6 rounded-lg hover:bg-[#333333] 
                         transition-all duration-200"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials - Commented out for now */}
      {/* <section className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-12">What Our Users Say</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-[#2a2a2a] p-6 rounded-lg">
                <div className="flex items-center mb-4">
                  <img src={testimonial.avatar} alt={testimonial.name} className="h-10 w-10 rounded-full mr-3" />
                  <h3 className="font-semibold">{testimonial.name}</h3>
                </div>
                <p className="text-gray-400">{testimonial.feedback}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* FAQs */}
      <section className="py-20 px-6 bg-[#1a1a1a]">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center">
            Frequently Asked Questions
          </h2>
          
          {/* Split FAQs into left and right columns manually */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-6">
              {faqs.filter((_, idx) => idx % 2 === 0).map((faq) => (
                <div 
                  key={faq.id} 
                  className={`bg-[#2a2a2a] rounded-lg transition-all duration-200
                            ${expandedFaqs.has(faq.id) ? 'ring-2 ring-orange-500' : 'hover:bg-[#333333]'}`}
                >
                  <button
                    onClick={() => toggleFaq(faq.id)}
                    className="w-full p-6 text-left flex justify-between items-start"
                  >
                    <h3 className="font-semibold pr-8">{faq.question}</h3>
                    <ChevronRight 
                      className={`w-5 h-5 flex-shrink-0 transition-transform duration-200
                                ${expandedFaqs.has(faq.id) ? 'transform rotate-90' : ''}`}
                    />
                  </button>
                  
                  <div 
                    className={`overflow-hidden transition-all duration-200
                              ${expandedFaqs.has(faq.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                  >
                    <div className="px-6 pb-6">
                      <div 
                        className="text-gray-400 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ 
                          __html: faq.answer.replace(/\n/g, '<br/>') 
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              {faqs.filter((_, idx) => idx % 2 === 1).map((faq) => (
                <div 
                  key={faq.id} 
                  className={`bg-[#2a2a2a] rounded-lg transition-all duration-200
                            ${expandedFaqs.has(faq.id) ? 'ring-2 ring-orange-500' : 'hover:bg-[#333333]'}`}
                >
                  <button
                    onClick={() => toggleFaq(faq.id)}
                    className="w-full p-6 text-left flex justify-between items-start"
                  >
                    <h3 className="font-semibold pr-8">{faq.question}</h3>
                    <ChevronRight 
                      className={`w-5 h-5 flex-shrink-0 transition-transform duration-200
                                ${expandedFaqs.has(faq.id) ? 'transform rotate-90' : ''}`}
                    />
                  </button>
                  
                  <div 
                    className={`overflow-hidden transition-all duration-200
                              ${expandedFaqs.has(faq.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                  >
                    <div className="px-6 pb-6">
                      <div 
                        className="text-gray-400 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ 
                          __html: faq.answer.replace(/\n/g, '<br/>') 
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="mt-12 text-center">
            <p className="text-gray-400">
              Still have questions? {' '}
              <a 
                href="mailto:henosischat@gmail.com" 
                className="text-orange-500 hover:text-orange-400 transition-colors"
              >
                Contact our support team →
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 px-6 bg-gradient-to-r from-orange-500 to-red-500 text-center">
        <h2 className="text-4xl font-bold mb-6">Ready to Elevate Your AI Experience?</h2>
        <p className="text-xl text-white mb-8">
          Get started with Henosis Chat today and revolutionize your workflow.
        </p>
        <button 
          onClick={() => navigate('/register')}
          className="px-8 py-4 bg-white text-[#1a1a1a] rounded-lg 
                   transition-all duration-200 text-lg font-medium"
        >
          Get Started
        </button>
      </section>

      {/* Footer */}
      <footer className="border-t border-[#2a2a2a] py-12 px-6">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
            <span className="text-sm">© 2024 Henosis. All rights reserved.</span>
          </div>
          <div className="flex space-x-6">
            <Link to="/privacy" className="text-sm text-gray-400 hover:text-white">Privacy</Link>
            <Link to="/terms" className="text-sm text-gray-400 hover:text-white">Terms</Link>
            <a href="mailto:henosischat@gmail.com" className="text-sm text-gray-400 hover:text-white">Contact</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
