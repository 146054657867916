import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

/**
 * Converts URLs in text to clickable links
 * @param {string} text - Text that may contain URLs
 * @returns {Array} Array of text and link elements
 */
const processMessageWithLinks = (text) => {
  if (!text) return [];
  
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  let lastIndex = 0;
  const elements = [];
  let match;

  while ((match = urlPattern.exec(text)) !== null) {
    if (match.index > lastIndex) {
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, match.index)}
        </span>
      );
    }

    elements.push(
      <a
        key={`link-${match.index}`}
        href={match[0]}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-200 hover:text-blue-100 underline"
      >
        {match[0]}
      </a>
    );

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    elements.push(
      <span key={`text-${lastIndex}`}>
        {text.substring(lastIndex)}
      </span>
    );
  }

  return elements;
};

/**
 * ErrorPopup Component - Displays error messages in a modal popup
 * @param {Object} props - Component props
 * @param {Error|Response|string} props.error - The error to display
 * @param {Function} props.onClose - Callback function to close the popup
 */
const ErrorPopup = ({ error, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [serverMessage, setServerMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [debugInfo, setDebugInfo] = useState('');

  useEffect(() => {
    const handleError = async () => {
      console.group('ErrorPopup - handleError');

      try {
        // Case 1: Response object (from fetch)
        if (error instanceof Response) {
          const text = await error.text();
          try {
            const data = JSON.parse(text);
            setErrorMessage(data.error || '');
            setServerMessage(data.message || '');
            setErrorCode(data.code || '');
            setDebugInfo(data.debug_info || '');
          } catch {
            setServerMessage(text || 'Server Error');
          }
        }
        // Case 2: Error object with message property
        else if (error instanceof Error) {
          setServerMessage(error.message);
        }
        // Case 3: Object with error/message/code and debug info properties
        else if (error && typeof error === 'object') {
          setErrorMessage(error.error || '');
          setServerMessage(error.message || '');
          setErrorCode(error.code || '');
          setDebugInfo(error.debug_info || '');
        }
        // Case 4: String error
        else if (typeof error === 'string') {
          setServerMessage(error);
        }
      } catch (handlingError) {
        console.error('Error while handling error:', handlingError);
        setServerMessage('Failed to process error message');
      } finally {
        console.groupEnd();
      }
    };

    handleError();
  }, [error]);

  // Handle escape key press
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  // Handle overlay click
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-40"></div>
      
      {/* Popup */}
      <div className="bg-red-600 text-white p-6 rounded-lg z-50 relative w-11/12 max-w-md mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Error</h3>
          <button 
            onClick={onClose}
            className="hover:bg-red-700 rounded-full p-1 transition-colors duration-200"
            aria-label="Close Error Popup"
          >
            <X size={20} />
          </button>
        </div>
        <div className="space-y-2">
          {errorMessage && errorMessage !== serverMessage && (
            <p className="font-medium">{errorMessage}</p>
          )}
          
          {serverMessage && (
            <p className={`text-sm ${errorMessage ? 'border-t border-red-400 pt-2' : 'font-medium'}`}>
              {processMessageWithLinks(serverMessage)}
            </p>
          )}
          
          {errorCode && (
            <p className='text-sm font-semibold'>
              Code: {errorCode}
            </p>
          )}
          
          {debugInfo && (
            <p className='text-xs text-gray-300 italic'>
              {debugInfo}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
