// src/components/Pricing/PricingPage.js
import React, { useState, useEffect } from 'react';
import PricingTierCard from './PricingTierCard';
import BillingToggle from './BillingToggle';
import PageHeader from './PageHeader';
import { pricingTiers, getPlanFeatures } from './PricingData';
import { useNavigate } from 'react-router-dom';
import { getDashboardData } from '../../services/api';
import UnsubscribeModal from '../Modals/UnsubscribeModal';
import { Check, X } from 'lucide-react';

const PricingPage = ({ isLoggedIn, currentTier, onUpgrade }) => {
  // State management
  const [billingCycle, setBillingCycle] = useState('yearly');
  const [dashboardData, setDashboardData] = useState(null);
  const [dashboardError, setDashboardError] = useState(null);
  const [planFeatures, setPlanFeatures] = useState([]);
  const [currentTierError, setCurrentTierError] = useState(null);
  const [showMoreTiers, setShowMoreTiers] = useState(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [unsubscribeError, setUnsubscribeError] = useState(null);
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);

  const navigate = useNavigate();

  // Handle back to chat navigation
  const handleBackToChat = () => {
    navigate('/');
  };

  // Filter pricing tiers
  const topTiers = pricingTiers.filter(tier => tier.tier === 'top');
  const bottomTiers = pricingTiers.filter(tier => tier.tier === 'bottom');
  const initialTiers = [...topTiers];
  const additionalTiers = bottomTiers.filter(tier => tier.name !== 'Enterprise');
  const enterpriseTier = bottomTiers.filter(tier => tier.name === 'Enterprise');
  const allTiers = showMoreTiers ? [...initialTiers, ...additionalTiers, ...enterpriseTier] : initialTiers;

  // Fetch dashboard data
  useEffect(() => {
    if (isLoggedIn) {
      const fetchDashboard = async () => {
        try {
          const data = await getDashboardData();
          setDashboardData(data);
          console.log('Dashboard Data:', data);

          // Use the cleaned tier name to get features
          const cleanedTierName = data?.tier_name?.replace(" Monthly", "").replace(" Yearly", "") || 'Personal';
          const features = getPlanFeatures(cleanedTierName);
          console.log('Plan Features:', features);
          setPlanFeatures(features);
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
          setDashboardError('ERROR: Failed to load dashboard data.');
        }
      };
      fetchDashboard();
    }
  }, [isLoggedIn]);

  // Handle unsubscribe button click
  const handleUnsubscribe = () => {
    console.log('Current subscription:', dashboardData?.subscription);
    if (!dashboardData?.subscription?.stripe_subscription_id) {
      setUnsubscribeError('No active subscription found');
      return;
    }
    setIsUnsubscribeModalOpen(true);
  };

  // Confirm unsubscribe action
  const confirmUnsubscribe = async () => {
    setIsUnsubscribing(true);
    setUnsubscribeError(null);

    try {
      const response = await fetch('/api/stripe/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscriptionId: dashboardData.subscription.stripe_subscription_id
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to cancel subscription');
      }

      // Refresh the page to update subscription status
      window.location.reload();
    } catch (error) {
      setUnsubscribeError(error.message);
    } finally {
      setIsUnsubscribing(false);
      setIsUnsubscribeModalOpen(false);
    }
  };

  // Validate currentTier prop
  useEffect(() => {
    if (isLoggedIn && !currentTier) {
      setCurrentTierError('ERROR: Current plan information not available.');
    } else {
      setCurrentTierError(null);
    }
  }, [isLoggedIn, currentTier]);

  const calculateRemainingCredits = () => {
    if (!dashboardData || !dashboardData.tier_name) return null;
  
    const cleanedTierName = dashboardData.tier_name.replace(" Monthly", "").replace(" Yearly", "");
  
    const currentTierData = pricingTiers.find(tier => tier.name === cleanedTierName);
    if (!currentTierData) return null; // or handle the case where tier is not found
  
    const monthlyCost = currentTierData.monthlyPrice;
    if (monthlyCost === null) return null
    const creditsUsed = dashboardData.credits_used || 0;
    const remainingCredits = Math.max(monthlyCost - creditsUsed, 0);
  
    return remainingCredits.toFixed(2);
  };

  const getMonthlyPrice = () => {
    if (!dashboardData || !dashboardData.tier_name) return null;
  
    const cleanedTierName = dashboardData.tier_name.replace(" Monthly", "").replace(" Yearly", "");
    const currentTierData = pricingTiers.find(tier => tier.name === cleanedTierName);
    
    return currentTierData ? currentTierData.monthlyPrice : null;
  };
  

  if (isLoggedIn) {
    return (
      <div className="min-h-screen flex flex-col bg-[#1a1a1a] text-white">
        <PageHeader />
        <div className="flex-1 py-12 px-4 relative overflow-hidden">
          <div className="mx-auto w-full">
            {/* Manage Subscription Header */}
            <div className="text-center mb-8">
              <h1 className="text-4xl font-bold mb-4">
                Manage Subscription
              </h1>
            </div>

            {/* Main Content Area */}
            <div className="flex flex-col md:flex-row gap-8 mt-8 justify-center">
              {/* Usage Details */}
              <div className="md:w-1/4 bg-[#2a2a2a] rounded-xl p-6 flex flex-col justify-between">
                <div>
                  <h3 className="text-xl font-semibold mb-4">
                    Your Plan
                    <span className={`font-medium ml-2 ${dashboardData?.tier_name === "No Subscription" ? "text-red-500" : "text-green-500"}`}>
                      {dashboardData?.tier_name === "No Subscription" ? "INACTIVE" : dashboardData?.tier_name || "ACTIVE"}
                    </span>
                  </h3>
                  <div className="mb-4">
                    <h4 className="text-lg font-medium text-gray-300">Usage Details</h4>
                    <div className="flex flex-col mt-2 text-sm text-gray-400">
                      <div className="flex justify-between">
                        <span>Remaining Credits:</span>
                        {calculateRemainingCredits() !== null ? (
                          <span>{calculateRemainingCredits()}</span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <span>Purchased & Awarded:</span>
                        {getMonthlyPrice() !== null ? (
                          <span>${getMonthlyPrice()}</span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      <div className="mt-2 text-xs text-gray-400">
                        <p> This value represents your monthly credits from your plan</p>
                        <p> We will add purchased (one time) credits here soon</p>
                        <p> Credits expire after 60 days</p>
                        <p> Credits require active subscription to use</p>
                      </div>
                      {dashboardError && <div className="text-red-500 mt-2">{dashboardError}</div>}
                    </div>
                  </div>
                </div>
                <button className="w-full py-2 px-4 rounded-lg font-medium transition-all duration-200 bg-orange-500 hover:bg-orange-600 text-white">
                  Buy more credits
                </button>
              </div>

              {/* Plan Details */}
              <div className="md:w-1/4 bg-[#2a2a2a] rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-4">Plan Features</h3>
                {planFeatures.length === 0 ? (
                  <p className="text-red-500">No features found</p>
                ) : (
                  <ul className="space-y-2 text-sm text-gray-400">
                    {planFeatures.map((feature, index) => {
                      // More robust feature name extraction
                      const featureName = 
                        (typeof feature === 'object' && feature.name) 
                        ? feature.name 
                        : (typeof feature === 'string' ? feature : JSON.stringify(feature));
                      
                      const isIncluded = 
                        (typeof feature === 'object' && feature.included !== undefined) 
                        ? feature.included 
                        : true;

                      console.log('Rendering Feature:', {
                        featureName, 
                        isIncluded, 
                        originalFeature: feature
                      });

                      return (
                        <li key={index} className="flex items-center gap-3">
                          {isIncluded ? (
                            <Check className="w-5 h-5 text-orange-500" />
                          ) : (
                            <X className="w-5 h-5 text-gray-500" />
                          )}
                          <span className={isIncluded ? 'text-gray-200' : 'text-gray-400'}>
                            {featureName}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}
                
                {/* Debug information */}
                {planFeatures.length === 0 && (
                  <div className="mt-4 text-yellow-500">
                    <p>Debug Info:</p>
                    <pre>{JSON.stringify(dashboardData, null, 2)}</pre>
                  </div>
                )}
              </div>

              {/* Billing & Payment */}
              <div className="md:w-1/4 bg-[#2a2a2a] rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-6">Billing & Payment</h3>
                <div className="flex flex-col gap-3 text-sm text-gray-400 mb-6">
                  <div className="flex justify-between">
                    <span>Price:</span>
                    <span>
                      {dashboardData?.tier_name 
                        ? `$${pricingTiers.find(tier => tier.name === dashboardData.tier_name.replace(" Monthly", "").replace(" Yearly", ""))?.monthlyPrice || 0} / mo` 
                        : 'Free'
                      }
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Billing period:</span>
                    <span>
                      {dashboardData?.tier_name?.includes("Monthly") 
                        ? "Monthly" 
                        : dashboardData?.tier_name?.includes("Yearly") 
                        ? "Yearly" 
                        : "None"
                      }
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Renewal date:</span>
                    <span>
                      {dashboardData?.subscription?.period_end 
                        ? new Date(dashboardData.subscription.period_end).toLocaleDateString() 
                        : 'None'
                      }
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Subscription status:</span>
                    <span className={dashboardData?.subscription?.status === "canceled" ? "text-red-500" : "text-green-500"}>
                      {dashboardData?.subscription?.status === "canceled" ? "Cancelled" : "Active"}
                    </span>
                  </div>
                  {dashboardData?.subscription?.cancel_at_period_end && (
                    <div className="flex justify-between">
                      <span>Cancellation effective:</span>
                      <span className="text-red-500">
                        {new Date(dashboardData.subscription.canceled_at).toLocaleDateString()}
                      </span>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-3">
                  <div className="flex justify-between gap-3">
                    <button className="flex-1 py-2.5 px-4 rounded-lg font-medium transition-all duration-200 bg-[#3a3a3a] hover:bg-[#404040] text-gray-200">
                      Edit Billing
                    </button>
                    <button className="flex-1 py-2.5 px-4 rounded-lg font-medium transition-all duration-200 bg-[#3a3a3a] hover:bg-[#404040] text-gray-200">
                      View Invoices
                    </button>
                  </div>

                  {/* Conditionally render the unsubscribe button */}
                  {dashboardData?.subscription?.stripe_subscription_id && 
                    !dashboardData?.subscription?.cancel_at_period_end && (
                    <button
                      className="w-full py-2.5 px-4 rounded-lg font-medium transition-all duration-200 
                                 bg-red-600/10 hover:bg-red-600/20 text-red-400 text-sm
                                 disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={handleUnsubscribe}
                      disabled={isUnsubscribing}
                    >
                      {isUnsubscribing ? 'Cancelling...' : 'Unsubscribe'}
                    </button>
                  )}

                  {/* Error message for unsubscribe */}
                  {unsubscribeError && (
                    <p className="mt-2 text-sm text-red-400">
                      {unsubscribeError}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Billing Toggle */}
            <BillingToggle
              billingCycle={billingCycle}
              onChange={setBillingCycle}
            />

            {/* Plan Cards */}
            <div className="mt-12 justify-center w-full mx-auto">
              <div className="flex flex-wrap justify-center gap-8">
                {allTiers.map((tier) => (
                  <div key={tier.name} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 max-w-[380px]">
                    <PricingTierCard
                      tier={tier}
                      billingCycle={billingCycle}
                      currentTier={dashboardData?.tier_name?.replace(" Monthly", "").replace(" Yearly", "")}
                      isLoggedIn={isLoggedIn} // Pass the isLoggedIn prop
                    />
                  </div>
                ))}
              </div>

              {/* "Need More?" button */}
              {!showMoreTiers && (
                <div className="text-center mt-8">
                  <button
                    onClick={() => setShowMoreTiers(true)}
                    className="text-gray-400 hover:text-white transition-all duration-200"
                  >
                    Need more? <span className="text-orange-500 font-medium">View Team Options</span>
                  </button>
                </div>
              )}
              {showMoreTiers && (
                <div className="text-center mt-8">
                  <button
                    onClick={() => setShowMoreTiers(false)}
                    className="text-gray-400 hover:text-white transition-all duration-200"
                  >
                    Hide Team Options
                  </button>
                </div>
              )}
            </div>

            {/* Frequently Asked Questions */}
            <div className="mt-16 text-center">
              <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
              <p className="text-gray-400 mb-6">Can't find the answer you're looking for? Visit the <a href="https://discord.gg/FS8hUmnkFv" className="text-orange-500">#member-support Discord channel</a> for help.</p>

              <div className="flex flex-col items-center max-w-4xl mx-auto">
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">How does Henosis handle context?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis provides models complete access to your entire conversation history, and any files or documents you share, up to each model's unique maximum context length. We'll let you know if you exceed a model's context limit. This ensures that the AI has all the relevant information when providing solutions.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">Can I switch between different AI models?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Yes, Henosis allows you to switch between different AI models during a conversation without losing context. This gives you flexibility to choose the best model for the task at hand, or use a combination of models to complete a task, such as using a smart model to think about a task and a fast, cheap model to output a fully fixed file.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What is included in each plan?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    All plans provide access to multiple AI models, and the ability to upload various file formats (code, images, and audio). Higher tier plans offer increased usage limits and team collaboration capabilities.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">Does Henosis use my data to train AI models?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    No, your data stays yours. We do not use any of your data to train our AI models.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What file types are supported?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis supports a wide range of file formats, including code files, images, and audio files. There is also a 100MB file size limit per file.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">How does web search work?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis integrates web search with GPT models. This allows the AI to access real-time information from the web and use it to provide better informed answers. Henosis evaluates 10-20 relevant links before using them to provide trustworthy results.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What is a project workspace?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis lets you create separate workspaces to organize your different projects and conversations. This provides better organization as you scale.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">How can I use Henosis for code analysis?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis provides the AI complete access to your codebase and dependencies, enabling better code analysis and debugging compared to tools that only see snippets.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What if I have a long audio or video file?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Henosis allows you to process entire podcasts, and videos, to get transcripts with timestamps, highlights, and topic breakdowns without watching every second. Audio files are limited to 9.5 hours.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What are the advantages of the Team tiers?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Team and Team+ tiers offer shared projects, team collaboration, usage monitoring, and additional features designed for organizations needing scalable access to Henosis.
                  </p>
                </details>
                <details className="w-full bg-[#2a2a2a] p-4 rounded-lg mb-2">
                  <summary className="font-medium text-gray-300 cursor-pointer">What if I need more credits?</summary>
                  <p className="text-sm text-gray-400 mt-2">
                    Additional credits can be purchased in addition to your plan. They have a 60 day expiration date and require an active subscription to use.
                  </p>
                </details>
              </div>
            </div>
          </div>
        </div>

        {/* Unsubscribe Modal */}
        <UnsubscribeModal
          isOpen={isUnsubscribeModalOpen}
          onClose={() => setIsUnsubscribeModalOpen(false)}
          onConfirm={confirmUnsubscribe}
          isUnsubscribing={isUnsubscribing}
          unsubscribeError={unsubscribeError}
        />
      </div>
    );
  }

  // Non-logged in view
  return (
    <div className="min-h-screen flex flex-col bg-[#1a1a1a] text-white">
      <PageHeader />
      <div className="flex-1 py-12 px-4 relative overflow-hidden">
        <div className="mx-auto w-full">
          {/* Top Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Choose Your Plan
            </h1>
            <p className="text-gray-400 mx-auto">
              Access powerful AI models and features with our flexible pricing plans now in BETA
            </p>
          </div>

          <BillingToggle
            billingCycle={billingCycle}
            onChange={setBillingCycle}
          />

          {/* Container for both rows with relative positioning */}
          <div className="relative">
            {/* Top Tier Cards */}
            <div className="flex flex-wrap justify-center gap-8 mt-12 w-full mx-auto">
              {initialTiers.map((tier) => (
                <div key={tier.name} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 max-w-[380px]">
                  <PricingTierCard
                    tier={tier}
                    billingCycle={billingCycle}
                    isLoggedIn={isLoggedIn} // Pass the isLoggedIn prop
                  />
                </div>
              ))}
            </div>

            {/* Bottom Tier Cards - Positioned lower and only shown when "Need More?" is clicked */}
            {showMoreTiers && (
              <div className="flex flex-wrap justify-center gap-8 mt-[10vh] relative w-full mx-auto">
                {/* Gradient overlay */}
                <div
                  className="absolute -top-20 left-0 right-0 h-40
                  bg-gradient-to-b from-[#1a1a1a] to-transparent z-10"
                />
                {additionalTiers.map((tier) => (
                  <div key={tier.name} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 max-w-[380px]">
                    <PricingTierCard
                      tier={tier}
                      billingCycle={billingCycle}
                      isLoggedIn={isLoggedIn} // Pass the isLoggedIn prop
                    />
                  </div>
                ))}
                {enterpriseTier.map((tier) => (
                  <div key={tier.name} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 max-w-[380px]">
                    <PricingTierCard
                      tier={tier}
                      billingCycle={billingCycle}
                      isLoggedIn={isLoggedIn} // Pass the isLoggedIn prop
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* "Need More?" button */}
          {!showMoreTiers && (
            <div className="text-center mt-8">
              <button
                onClick={() => setShowMoreTiers(true)}
                className="text-gray-400 hover:text-white transition-all duration-200"
              >
                Need more? <span className="text-orange-500 font-medium">View Team Options</span>
              </button>
            </div>
          )}
          {showMoreTiers && (
            <div className="text-center mt-8">
              <button
                onClick={() => setShowMoreTiers(false)}
                className="text-gray-400 hover:text-white transition-all duration-200"
              >
                Hide Team Options
              </button>
            </div>
          )}

          {/* Footer text */}
          <div className="mt-16 text-center">
            <p className="text-gray-400">
              All plans include basic features like chat history. Usage varies based with model selection.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
