import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Plus, Edit2, MessageCircle, ChevronRight, User, Cog, LogOut, CreditCard, Upload, createLucideIcon, PanelLeft, PanelRight, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import { fetchUsername } from '../../services/api';
import ReactDOM from 'react-dom';

// Loading indicator component
const LoadingIndicator = () => (
  <div className="flex items-center justify-center py-4 text-gray-400">
    <div className="animate-pulse flex space-x-2">
      <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
      <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
      <div className="w-2 h-2 bg-gray-400 rounded-full"></div>
    </div>
  </div>
);

// Main component for the thread sidebar
const ThreadSidebar = ({
  threads,
  threadOrder,
  currentThreadId,
  onEditThread,
  onNewChat,
  onMouseLeave,
  onThreadChange,
  onDeleteThread,
  isOpen,
  hasMore,
  onLoadMore, // This is passed as a prop, so we don't declare it again
  isLoading: parentIsLoading,
  isLoadingMore, // Add isLoadingMore prop
  position = 'left',
  onToggleSidebar,
  onLogout,
  userMenuContainerRef,
}) => {
  // State for tracking loading states of individual threads
  const [loadingStates, setLoadingStates] = useState(new Map());
  // State for tracking errors
  const [error, setError] = useState(null);
  // State for storing the username
  const [username, setUsername] = useState(null);
  // State for tracking whether the username is loading
  const [usernameLoading, setUsernameLoading] = useState(true);
  // State for tracking if the user menu is open
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  // Ref for the observer
  const observerRef = useRef(null);
  // Ref for the list
  const listRef = useRef(null);
  // Navigation hook for routing
  const navigate = useNavigate();
  const userMenuRef = useRef(null);
  // State for tracking if it's mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 450);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to handle the click of a thread, loads thread if needed.
  const handleThreadClick = useCallback(async (threadId) => {
    if (!threads?.has(threadId)) return;

    const thread = threads.get(threadId);
    try {
      // Only force reload if we have no messages
      const needsLoad = !thread.messages || thread.messages.length === 0;
      onThreadChange(threadId, needsLoad);
    } catch (err) {
      setError('Failed to load thread content');
    }
  }, [threads, onThreadChange]);

  // Function to handle intersection of observer and load threads.
  const observerCallback = useCallback(
    (entries) => {
      const target = entries[0];

      if (target.isIntersecting && hasMore && !isLoadingMore) {
        onLoadMore();
      }
    },
    [hasMore, isLoadingMore, onLoadMore]
  );

  // Handles the logic for infinite scrolling via an observer
  useEffect(() => {
    if (!listRef.current || !hasMore || isLoadingMore || !observerRef.current) {
      return;
    }

    const options = {
      root: listRef.current,
      rootMargin: '100px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(observerCallback, options);
    observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
      observer.disconnect();
    };
  }, [hasMore, isLoadingMore, observerCallback, listRef]);

  // Renders the thread items
  const renderThreadItem = useCallback((threadId) => {
    const thread = threads?.get(threadId);
    if (!thread) {
      return null;
    }

    const isLoading = loadingStates.get(threadId);
    const isActive = currentThreadId === threadId;

    return (
      <div
        key={`thread-${threadId}`}
        onClick={() => handleThreadClick(threadId)}
        className={`
          relative group rounded-lg
          flex items-center gap-3 px-4 py-3 mb-1
          cursor-pointer transition-all duration-200
          text-gray-300 hover:text-white
          ${isActive ? 'bg-[#2a2a2a] text-white' : 'hover:bg-[#232323]' }
          ${isLoading ? 'opacity-50' : ''}
        `}
      >
        <MessageCircle
          size={16}
          className={`flex-shrink-0 ${
            isActive ? 'text-orange-500' : 'text-gray-500'
          }`}
        />

        <span className="flex-grow truncate text-sm">
          {thread.name}
          {isLoading && ' (Loading...)'}
        </span>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onEditThread(threadId, thread.name);
          }}
          className="opacity-0 group-hover:opacity-100
                     transition-opacity duration-200
                     hover:text-orange-500"
          aria-label="Edit Thread"
        >
          <Edit2 size={14} />
        </button>
      </div>
    );
  }, [threads, currentThreadId, loadingStates, handleThreadClick, onEditThread]);

  // Fetch username on component mount
  useEffect(() => {
    const fetchUser = async () => {
      setUsernameLoading(true);
      try {
        const userData = await fetchUsername();
        setUsername(userData.username);
      } catch (err) {
        console.error('ThreadSidebar: Error fetching username:', err);
        setError('Failed to fetch username');
      } finally {
        setUsernameLoading(false);
      }
    };
    fetchUser();
  }, []);

  // Error Message component to handle display of error messages
  const ErrorMessage = ({ message }) => (
    <div className="text-red-500 p-4 text-sm text-center">
      {message}
      <button
        onClick={() => setError(null)}
        className="ml-2 text-orange-500 hover:text-orange-600"
      >
        Dismiss
      </button>
    </div>
  );

  // Function to toggle the user menu.
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const renderUserMenu = () => {
    const menuStyle = {
      position: 'absolute',
      width: '16rem',
      zIndex: 1000,
      // On mobile, position above and a bit left of the profile button
      ...(isMobile ? {
        bottom: 'auto',
        top: '0',
        left: '-100px', // shifted 10px to the left
        transform: 'translateY(-100%)',
        marginBottom: '4px'
      } : {
      // On desktop, maintain the original positioning
        bottom: '0',
        ...(position === 'left' ? {
          left: '100%',
          marginLeft: '4px',
          marginBottom: '4px',
        } : {
          right: '100%',
          marginRight: '4px',
          marginBottom: '4px',
        })
      })
    };

    return userMenuContainerRef.current ? ReactDOM.createPortal(
      <div
        className="absolute mb-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pointer-events-auto"
        style={menuStyle}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div
          className={`py-1 bg-[#1a1a1a]
               ${position === 'left' ? 'rounded-tr-xl rounded-br-xl' : 'rounded-tl-xl rounded-bl-xl'}`} role="none">
          <button
            onClick={(e) => { e.stopPropagation(); navigate('/dashboard'); toggleUserMenu();}}
            className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-[#3a3a3a] hover:text-orange-500 transition-colors duration-200 text-left"
            role="menuitem"
          >
            <LayoutDashboard size={16} className="mr-2"/>
            Dashboard
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); navigate('/profile'); toggleUserMenu();}}
            className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-[#3a3a3a] hover:text-orange-500 transition-colors duration-200 text-left"
            role="menuitem"
          >
            <User size={16} className="mr-2"/>
            Profile
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); navigate('/pricing'); toggleUserMenu();}}
            className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-[#3a3a3a] hover:text-orange-500 transition-colors duration-200 text-left"
            role="menuitem"
          >
            <CreditCard size={16} className="mr-2"/>
            Manage Subscription
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); window.open('https://discord.gg/GN8cx3G6BT', '_blank'); toggleUserMenu();}}
            className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-[#3a3a3a] hover:text-orange-500 transition-colors duration-200 text-left"
            role="menuitem"
          >
            <img src="/discord.png" alt="Discord" className="mr-2" width="16" height="16" />
            Henosis Discord
          </button>
          <button
            onClick={(e) => { e.stopPropagation(); onLogout(); toggleUserMenu(); }}
            className="flex items-center w-full px-4 py-2 text-gray-300 hover:bg-[#3a3a3a] hover:text-orange-500 transition-colors duration-200 text-left"
            role="menuitem"
          >
            <LogOut size={16} className="mr-2"/>
            Log Out
          </button>
        </div>
      </div>,
      userMenuContainerRef.current
    ) : null;
  };

  useEffect(() => {
    if (!isOpen) {
      setIsUserMenuOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (listRef.current) {
    }
  }, [listRef]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
      }
    };

    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [listRef]);

  return (
    <ErrorBoundary>
      <div
        className={`
          fixed top-0 bottom-0
          w-full sm:w-72
          max-w-[100vw]
          bg-[#1a1a1a] border-[#2a2a2a]
          transition-all duration-300 ease-in-out
          flex flex-col overflow-hidden z-20 shadow-xl
          ${position === 'left' ? 'left-0 border-r' : 'right-0 border-l'}
          ${position === 'left'
            ? 'sm:rounded-tr-xl sm:rounded-br-xl'
            : 'sm:rounded-tl-xl sm:rounded-bl-xl'
          }
          ${isOpen
            ? 'translate-x-0'
            : position === 'left'
              ? '-translate-x-full'
              : 'translate-x-full'
          }
        `}
        onMouseLeave={onMouseLeave}
      >
        <div className={`
          flex flex-col h-full w-full
          ${isOpen ? 'opacity-100' : 'opacity-0'}
          transition-opacity duration-300
        `}>
          <div className="flex items-center mx-3 my-2 gap-2 relative">
            <button
              onClick={() => onToggleSidebar()}
              className="p-2
                rounded-lg transition-all duration-200
                hover:bg-[#2a2a2a] text-gray-400 hover:text-white"
            >
              {position === 'left' ? <PanelRight size={18} /> : <PanelLeft size={18} />}
            </button>
            <button
              onClick={onNewChat}
              className="p-2
                bg-orange-500 hover:bg-orange-600
                rounded-lg transition-all duration-200
                text-white font-medium absolute right-3 top-[50%] -translate-y-[50%]"
            >
              <MessageCircle size={18} className="relative">
                <Plus size={10} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              </MessageCircle>
            </button>
          </div>

          <div className="h-px bg-[#2a2a2a] mx-3 mb-3" />

          {error && <ErrorMessage message={error} />}

          <div
            ref={listRef}
            className="flex-grow overflow-y-auto px-3
              scrollbar-thin scrollbar-thumb-[#3a3a3a]
              scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]"
            style={{ maxHeight: 'calc(100vh - 120px)' }}
          >
            {parentIsLoading ? (
              <div className="text-center py-4 text-gray-400">
                Loading threads...
              </div>
            ) : threadOrder?.length === 0 ? (
              <div className="text-center py-4 text-gray-400">
                No conversations yet
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  {threadOrder?.map(renderThreadItem)}
                </div>
                {hasMore && (
                  <div
                    ref={observerRef}
                    className="py-2 my-2"
                    style={{ minHeight: '40px' }}
                  >
                    {isLoadingMore ? (
                      <LoadingIndicator />
                    ) : (
                      <div className="text-center text-gray-400 text-sm">
                        Scroll for more
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          {/* Bottom section with buttons */}
          <div className="px-3 py-2 bg-[#1a1a1a] h-[44px] flex items-center">
            {/* User profile button */}
            <div className="relative flex-grow" ref={userMenuRef}>
              <button
                onClick={toggleUserMenu}
                className={`flex justify-between items-center rounded-lg p-2 hover:bg-[#2a2a2a]  text-gray-300 transition-colors duration-200 group ${isUserMenuOpen ? 'text-orange-500' : 'hover:text-white'}`}
                style={{ width: '100%' }}
              >
                <div className="flex items-center gap-2 truncate">
                  <User size={18} className="text-gray-500"/>
                  {usernameLoading ? (
                    <span className='text-sm'>Loading...</span>
                  ) : (
                    <span className='text-sm'>{username || 'User'}</span>
                  )}
                </div>
                <div
                  className="hover:text-orange-500 flex"
                >
                  <div className="relative inline-block text-left">
                    <button className="focus:outline-none" aria-haspopup="true" aria-expanded="true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </button>
              {/* User Menu */}
              {isUserMenuOpen && userMenuContainerRef.current && renderUserMenu()}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ThreadSidebar;
