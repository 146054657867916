// src/hooks/useThreads.js
import { useState, useCallback, useEffect, useRef } from 'react';
import {
    getConversations,
    saveConversation,
    deleteThread,
    updateThreadName,
    API_BASE_URL
} from '../services/api';

// Helper function to get content preview from various content formats
const getContentPreview = (content) => {

    if (!content) return '[Empty content]';

    // Handle string content
    if (typeof content === 'string') {
        return content.substring(0, 50);
    }

    // Handle array content
    if (Array.isArray(content)) {
        const textContent = content.find(item => {
            if (typeof item === 'string') return true;
            if (item?.type === 'text' && item?.text) return true;
            if (item?.text && typeof item.text === 'string') return true;
            return false;
        });

        if (textContent) {
            if (typeof textContent === 'string') {
                return textContent.substring(0, 50);
            }
            if (textContent.text) {
                return textContent.text.substring(0, 50);
            }
        }

        return '[Complex array content]';
    }

    // Handle object content
    if (typeof content === 'object' && content !== null) {
        if (content.text && typeof content.text === 'string') {
            return content.text.substring(0, 50);
        }

        if (content.content && typeof content.content === 'string') {
            return content.content.substring(0, 50);
        }

        if (Array.isArray(content.parts)) {
            const textPart = content.parts.find(part => typeof part === 'string' || part?.text);
            if (textPart) {
                const text = typeof textPart === 'string' ? textPart : textPart.text;
                return text.substring(0, 50);
            }
        }

        const stringProps = Object.entries(content)
            .find(([_, value]) => typeof value === 'string');
        if (stringProps) {
            return stringProps[1].substring(0, 50);
        }
    }

    return '[Unsupported content format]';
};

/**
 * Custom hook to manage chat threads and their state
 * Handles thread loading, creation, deletion, and updates
 *
 * @param {Object} params Configuration parameters
 * @param {Function} params.onLogout Callback for handling logout events
 * @param {string} params.defaultModel Default AI model to use for new threads
 * @returns {Object} Thread state and management functions
 */
const useThreads = ({ onLogout, defaultModel }) => {
    const [threads, setThreads] = useState(new Map());
    const [threadOrder, setThreadOrder] = useState([]);
    const [currentThreadId, setCurrentThreadId] = useState(null);
    const [currentThreadIndex, setCurrentThreadIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingStates, setLoadingStates] = useState(new Map());
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [isPaginationLoading, setIsPaginationLoading] = useState(false);
    const loadedThreadsRef = useRef(new Set());
    const threadsRef = useRef(new Map());
    const loadingThreadsRef = useRef(new Set());


    /**
     * Loads content for a specific thread
     * Handles fetching messages and updating thread state
     *
     * @param {string} threadId - UUID of the thread to load
     */
    const loadThreadContent = useCallback(async (threadId) => {

        if (!threadId || !threads.has(threadId)) {
            console.error('loadThreadContent: Invalid thread ID or thread not found:', threadId);
            return;
        }

        const existingThread = threads.get(threadId);
        if (existingThread?.isLoaded && existingThread.messages?.length > 0) {
            return;
        }

        if (existingThread?.messages?.length > 0 && !existingThread.isLoaded) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        if (loadingThreadsRef.current.has(threadId)) {
            return;
        }

        try {
            loadingThreadsRef.current.add(threadId);
            setLoadingStates(prev => new Map(prev).set(threadId, true));

            const messages = await getConversations('thread', {
                thread_uid: threadId
            });

            if (messages.length === 0 && existingThread?.messages?.length > 0) {
                setThreads(prev => {
                    const updated = new Map(prev);
                    const thread = updated.get(threadId);
                    if (thread) {
                        updated.set(threadId, {
                            ...thread,
                            isLoaded: true,
                            lastLoaded: new Date().toISOString()
                        });
                    }
                    return updated;
                });
                return;
            }

            setThreads(prev => {
                const updated = new Map(prev);
                const thread = updated.get(threadId);
                if (thread) {
                    updated.set(threadId, {
                        ...thread,
                        messages: messages.length > 0 ? messages : thread.messages,
                        isLoaded: true,
                        lastLoaded: new Date().toISOString()
                    });
                }
                return updated;
            });

            loadedThreadsRef.current.add(threadId);

        } catch (error) {
            console.error('loadThreadContent: Error:', error);
            setError(error.message);
        } finally {
            loadingThreadsRef.current.delete(threadId);
            setLoadingStates(prev => {
                const updated = new Map(prev);
                updated.delete(threadId);
                return updated;
            });
        }
    }, [threads, currentThreadId, setError]);

    /**
     * Fetches initial thread data and sets up the thread state
     * Loads the first thread's content automatically
     */
    const fetchConversations = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getConversations('list', { limit: 50 });

            if (Array.isArray(response) && response.length > 0) {
                setThreads(prevThreads => {
                    const threadMap = new Map(prevThreads);
                    
                    response.forEach(thread => {
                        if (!threadMap.has(thread.thread_uid)) {
                            const threadObj = {
                                id: thread.thread_uid,
                                name: thread.name,
                                isLoaded: false,
                                messages: thread.messages || [],
                                selectedModel: thread.selectedModel || defaultModel,
                                pasteCounter: thread.pasteCounter,
                                preview: thread.preview,
                                isShared: thread.isShared,
                                shareSettings: thread.shareSettings
                            };
                            threadMap.set(thread.thread_uid, threadObj);
                        }
                    });

                    return threadMap;
                });

                setThreadOrder(prevOrder => {
                    const existingIds = new Set(prevOrder);
                    const newIds = response
                        .map(thread => thread.thread_uid)
                        .filter(id => !existingIds.has(id));
                    
                    return [...prevOrder, ...newIds];
                });

                setOffset(response.length);
                setHasMore(response.length >= 50);

                if (response.length > 0 && !currentThreadId) {
                    const firstThreadId = response[0].thread_uid;
                    setCurrentThreadId(firstThreadId);
                    setCurrentThreadIndex(0);

                    if (!loadedThreadsRef.current.has(firstThreadId)) {
                        const firstThreadMessages = await getConversations('thread', {
                            thread_uid: firstThreadId
                        });

                        setThreads(prevThreads => {
                            const updated = new Map(prevThreads);
                            const existingThread = updated.get(firstThreadId);
                            if (existingThread) {
                                updated.set(firstThreadId, {
                                    ...existingThread,
                                    messages: firstThreadMessages,
                                    isLoaded: true
                                });
                            }
                            return updated;
                        });

                        loadedThreadsRef.current.add(firstThreadId);
                    }
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('useThreads: Error fetching conversations:', error);
            setError(error.message);
            if (error.message.includes('401')) {
                onLogout();
            }
        } finally {
            setIsLoading(false);
        }
    }, [onLogout, defaultModel, currentThreadId]);

    /**
     * Loads more threads when scrolling
     */
    const loadMoreThreads = useCallback(async (onComplete) => {

        if (!hasMore || isPaginationLoading) {
            return;
        }

        try {
            setIsPaginationLoading(true);


            const response = await getConversations('scroll', {
                offset,
                limit: 50
            });


            if (Array.isArray(response) && response.length > 0) {
                setThreads(prev => {
                    const updated = new Map(prev);
                    const newThreads = [];

                    response.forEach(thread => {
                        if (!updated.has(thread.thread_uid)) {
                            const threadObj = {
                                id: thread.thread_uid,
                                name: thread.name,
                                isLoaded: false,
                                messages: [],
                                selectedModel: thread.selectedModel || defaultModel,
                                pasteCounter: thread.pasteCounter,
                                preview: thread.preview,
                                isShared: thread.isShared,
                                shareSettings: thread.shareSettings
                            };
                            updated.set(thread.thread_uid, threadObj);
                            newThreads.push(thread.thread_uid);
                        }
                    });

                    return updated;
                });

                setThreadOrder(prev => {
                    const existingIds = new Set(prev);
                    const newIds = response
                        .map(thread => thread.thread_uid)
                        .filter(id => !existingIds.has(id));

                    const updatedOrder = [...prev, ...newIds];

                    return updatedOrder;
                });

                setOffset(currentOffset => {
                    const newOffset = currentOffset + response.length;
                    return newOffset;
                });

                setHasMore(response.length >= 50);

            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('useThreads: Error loading more threads:', error);
            setError(error.message);
        } finally {
            setIsPaginationLoading(false);
            onComplete();
        }
    }, [hasMore, isPaginationLoading, offset, defaultModel, threadOrder.length]);

    /**
     * Creates a new thread with the specified model
     * Handles permanent thread creation with UUID support
     *
     * @param {string} selectedModel - The AI model to use for the new thread
     * @returns {Promise<Object>} The created thread object
     */
    const handleNewThread = useCallback(async (newThread) => {
        try {
            const savedThread = await saveConversation(
                newThread.name,
                [],
                newThread.thread_uid,
                newThread.selectedModel || defaultModel,
                null
            );

            if (!savedThread || !savedThread.thread_uid) {
                throw new Error('Failed to get valid thread ID from server');
            }

            const threadObj = {
                id: savedThread.thread_uid,
                name: savedThread.name || 'New Chat',
                messages: [],
                selectedModel: newThread.selectedModel || defaultModel,
                isLoaded: true,
                isTemporary: false,
                preview: null,
                pasteCounter: 0,
                isShared: false,
                shareSettings: null,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString()
            };

            setThreads(prev => {
                const updated = new Map(prev);
                updated.set(savedThread.thread_uid, threadObj);
                return updated;
            });

            setThreadOrder(prev => [savedThread.thread_uid, ...prev]);

            setCurrentThreadId(savedThread.thread_uid);
            setCurrentThreadIndex(0);

            loadedThreadsRef.current.add(savedThread.thread_uid);


            return savedThread;
        } catch (error) {
            console.error('useThreads: Error creating new thread:', error);
            setError('Failed to create new thread');
            return null;
        }
    }, [setThreads, setThreadOrder, setCurrentThreadId, setCurrentThreadIndex, setError, defaultModel]);

    /**
     * Handles thread selection and content loading
     * @param {string} threadId UUID of the thread to select
     * @param {boolean} needsLoad Whether to force reload thread content
     */
    const handleThreadChange = useCallback(async (threadId, needsLoad = false) => {

        if (!threads.has(threadId)) {
            console.error('handleThreadChange: Invalid thread ID:', threadId);
            return;
        }

        if (threadId === currentThreadId && !needsLoad) {
            return;
        }

        const newIndex = threadOrder.indexOf(threadId);
        setCurrentThreadId(threadId);
        setCurrentThreadIndex(newIndex);

        if (needsLoad) {
            loadedThreadsRef.current.delete(threadId);

        }

        if ((needsLoad || !loadedThreadsRef.current.has(threadId)) &&
            !loadingThreadsRef.current.has(threadId)) {
            try {
                await loadThreadContent(threadId);
            } catch (error) {
                setError('Failed to load thread content');
            }
        }
    }, [threadOrder, loadThreadContent, threads, setCurrentThreadId, setCurrentThreadIndex, setError, currentThreadId]);

    /**
     * Deletes a thread and updates state
     * @param {string} threadId ID of the thread to delete
     */
    const handleDeleteThread = useCallback(async (threadId) => {

        try {
            if (!String(threadId).startsWith('temp-')) {
                await deleteThread(threadId);
            }

            const currentIndex = threadOrder.indexOf(threadId);
            let nextThreadId = null;

            if (threadOrder.length > 1) {
                if (currentIndex < threadOrder.length - 1) {
                    nextThreadId = threadOrder[currentIndex + 1];
                } else {
                    nextThreadId = threadOrder[currentIndex - 1];
                }
            }

            loadedThreadsRef.current.delete(threadId);

            setThreads(prev => {
                const updated = new Map(prev);
                updated.delete(threadId);
                return updated;
            });

            setThreadOrder(prev => {
                const updatedOrder = prev.filter(id => id !== threadId);
                return updatedOrder;
            });

            if (currentThreadId === threadId) {
                if (nextThreadId) {
                    loadingThreadsRef.current.clear();
                    loadedThreadsRef.current.clear();

                    setCurrentThreadId(nextThreadId);
                    setCurrentThreadIndex(
                        threadOrder.indexOf(nextThreadId)
                    );
                } else {
                    handleNewThread(defaultModel, false);
                }
            }


        } catch (error) {
            console.error('useThreads: Error deleting thread:', error);
            setError(error.message);
        }
    }, [
        currentThreadId,
        threadOrder,
        handleNewThread,
        defaultModel,
        setCurrentThreadId,
        setCurrentThreadIndex
    ]);

    /**
     * Updates a thread's name
     * @param {string} threadId ID of the thread to update
     * @param {string} newName New name for the thread
     */
    const handleUpdateThreadName = useCallback(async (threadId, newName) => {
        try {
            await updateThreadName(threadId, newName);

            setThreads(prev => {
                const updated = new Map(prev);
                const thread = updated.get(threadId);
                if (thread) {
                    updated.set(threadId, { ...thread, name: newName });
                }
                return updated;
            });
        } catch (error) {
            console.error('useThreads: Error updating thread name:', error);
            setError(error.message);
        }
    }, []);

    useEffect(() => {
        const initializeThreads = async () => {
            try {
                if (threads.size === 0 && !currentThreadId) {
                    await fetchConversations();
                    if (threadOrder.length === 0) {
                        console.error('useThreads: No threads found after initialization');
                    }
                } else {
                }
            } catch (error) {
                console.error('useThreads: Initialization error:', error);
            }
        };

        initializeThreads();
    }, [fetchConversations, handleNewThread, defaultModel, threadOrder.length, threads.size, currentThreadId]);

    useEffect(() => {
        if (!currentThreadId || !threads.size) return;

        const thread = threads.get(currentThreadId);
        if (!thread) return;

        if (!thread.isTemporary &&
            !loadedThreadsRef.current.has(currentThreadId) &&
            !loadingThreadsRef.current.has(currentThreadId)) {
            loadThreadContent(currentThreadId);
        }
    }, [currentThreadId, threads, loadThreadContent]);


    return {
        threads,
        setThreads,
        threadOrder,
        setThreadOrder,
        currentThreadId,
        setCurrentThreadId,
        currentThreadIndex,
        isLoading,
        loadingStates,
        error,
        hasMore,
        handleThreadChange,
        handleDeleteThread,
        handleUpdateThreadName,
        loadMoreThreads,
        handleNewThread,
        fetchConversations,
        isPaginationLoading
    };
};

export default useThreads;
