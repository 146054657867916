import React, { useRef, useEffect, useState, useCallback } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import MessageItem from './MessageItem';
import { ChevronDown } from 'lucide-react';

const MessageList = ({ messages, shouldAutoScroll, pastes }) => {
  const listRef = useRef(null);
  const messageHeightsRef = useRef({});
  const [listKey, setListKey] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isInitialized, setIsInitialized] = useState(false);
  const lastMessageLengthRef = useRef(messages.length);
  const [showScrollButton, setShowScrollButton] = useState(false); // Default to false

  const scrollToBottom = useCallback(() => {
    if (listRef.current) {
      const scrollIndex = messages.length - 1;
      
      // First scroll using react-window's method
      listRef.current.scrollToItem(scrollIndex, 'end');
      
      // Then force a complete scroll after a brief delay
      setTimeout(() => {
        const container = listRef.current._outerRef;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'auto'
          });
        }
      }, 100);
    }
  }, [messages.length]);

  // Handle scroll button visibility
  const handleScroll = useCallback((event) => {
    const container = event.currentTarget;
    
    // Check if content is actually scrollable
    const isScrollable = container.scrollHeight > container.clientHeight;
    
    // Check if not at bottom (with small threshold)
    const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;
    
    // Only show button if content is scrollable AND not at bottom
    setShowScrollButton(isScrollable && !isNearBottom);
  }, []);

  // Initial scroll position and new message handling
  useEffect(() => {
    if (!isInitialized && messages.length > 0) {
      setIsInitialized(true);
      
      // For shared threads, start at top
      if (!shouldAutoScroll && listRef.current?._outerRef) {
        listRef.current._outerRef.scrollTop = 0;
        setShowScrollButton(true); // Show button when at top of shared thread
      } else if (shouldAutoScroll) {
        // For regular chats, scroll to bottom
        scrollToBottom();
        setShowScrollButton(false);
      }
    } else if (isInitialized && messages.length > lastMessageLengthRef.current) {
      // New message arrived
      if (shouldAutoScroll) {
        scrollToBottom();
        setShowScrollButton(false);
      }
    }
    
    lastMessageLengthRef.current = messages.length;
  }, [messages.length, shouldAutoScroll, scrollToBottom, isInitialized]);

  // Add resize observer for dynamic content
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (shouldAutoScroll) {
        scrollToBottom();
      }
    });

    const container = listRef.current?._outerRef;
    if (container) {
      observer.observe(container);
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      observer.disconnect();
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollToBottom, shouldAutoScroll, handleScroll]);

  // Check scroll state when messages change
  useEffect(() => {
    if (listRef.current?._outerRef) {
      const container = listRef.current._outerRef;
      const isScrollable = container.scrollHeight > container.clientHeight;
      const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;
      setShowScrollButton(isScrollable && !isNearBottom);
    }
  }, [messages]);

  const setMessageHeight = useCallback((index, height) => {
    const currentHeight = messageHeightsRef.current[index];
    const newHeight = Math.max(height + 24, 100);

    if (currentHeight !== newHeight) {
      messageHeightsRef.current[index] = newHeight;
      
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
        
        if (shouldAutoScroll && index === messages.length - 1) {
          requestAnimationFrame(scrollToBottom);
        }
      }
    }
  }, [messages.length, scrollToBottom, shouldAutoScroll]);

  const getItemSize = useCallback((index) => {
    return messageHeightsRef.current[index] || 100;
  }, []);

  const renderRow = useCallback(
    ({ index, style }) => (
      <MessageItem
        index={index}
        style={{
          ...style,
          top: `${parseFloat(style.top) + 12}px`,
          height: `${parseFloat(style.height) - 24}px`
        }}
        data={{
          messages,
          setMessageHeight,
          listRef
        }}
        pastes={pastes}
      />
    ),
    [messages, setMessageHeight, pastes]
  );

  return (
    <div className="h-full bg-[#1a1a1a] relative">
      <AutoSizer>
        {({ height, width }) => {
          if (height !== dimensions.height || width !== dimensions.width) {
            setDimensions({ height, width });
          }
          
          return (
            <List
              key={listKey}
              height={height}
              itemCount={messages.length}
              itemSize={getItemSize}
              width={width}
              ref={listRef}
              overscanCount={5}
              className="scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full"
              onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
                requestAnimationFrame(() => {
                  if (listRef.current) {
                    listRef.current.resetAfterIndex(0, true);
                  }
                });
              }}
            >
              {renderRow}
            </List>
          );
        }}
      </AutoSizer>
      
      {/* Scroll to bottom button */}
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="absolute bottom-4 right-4 p-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition-colors"
          aria-label="Scroll to bottom"
        >
          <ChevronDown size={24} />
        </button>
      )}
    </div>
  );
};

export default React.memo(MessageList);
