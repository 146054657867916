// src/components/Pricing/CheckoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CheckoutButton = ({ productId, currentTier, tierName, isLoggedIn }) => {
  const navigate = useNavigate();
  
  const cleanedCurrentTier = currentTier?.replace(" Monthly", "").replace(" Yearly", "");
  
  const buttonText = !cleanedCurrentTier || cleanedCurrentTier === "free"
    ? "Start Subscription"
    : cleanedCurrentTier === tierName
    ? "Currently Subscribed"
    : "Change Plan";

  const isDisabled = cleanedCurrentTier === tierName;

  const handleClick = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      // Pass the productId as state when navigating to register
      navigate('/register', { 
        state: { 
          productId: productId,
          returnToSubscription: true
        }
      });
    } else {
      // If logged in, submit the form normally
      e.target.form.submit();
    }
  };

  return (
    <form action="/api/stripe/create-checkout-session" method="POST">
      <input type="hidden" name="productId" value={productId} />
      <button 
        type="submit"
        onClick={handleClick}
        className={`w-full py-3 px-4 rounded-lg font-medium
          transition-all duration-200
          ${
            isDisabled
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-orange-500 hover:bg-orange-600"
          } text-white`}
        disabled={isDisabled}
      >
        {buttonText}
      </button>
    </form>
  );
};

export default CheckoutButton;
