// src/hooks/useChat.js
import { useState, useRef, useCallback, useEffect } from 'react';
import { saveConversation, generateChatTitle, API_BASE_URL } from '../services/api';
import config from '../config';

/**
 * Custom hook to manage chat messages and related actions.
 * Handles message sending, thread management, and API interactions.
 *
 * @param {Object} params - Hook parameters
 * @param {Map} params.threads - Map of all threads
 * @param {Function} params.setThreads - Function to update threads Map
 * @param {string} params.currentThreadId - ID of currently selected thread
 * @param {Function} params.onLogout - Callback for logout action
 * @param {Array} params.pastes - Array of pasted content
 * @param {Function} params.setPastes - Function to update pastes
 * @param {string} params.customInstructions - Custom system instructions
 * @param {string} params.currentlySelectedModel - Currently selected AI model
 * @returns {Object} Chat state and actions
 */
const useChat = ({
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    onLogout,
    pastes,
    setPastes,
    customInstructions,
    currentlySelectedModel,
}) => {

    const [inputMessage, setInputMessage] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef(null);

    // Get current thread and its messages
    const currentThread = threads?.get(currentThreadId);
    const messages = currentThread?.messages || [];
    /**
     * Handles saving thread data to the backend
     * @param {string} threadName - Name of the thread
     * @param {Array} messageArray - Array of messages in the thread
     * @param {string} threadId - ID of the thread
     * @returns {Object} Saved thread data
     */
    const handleThreadSave = useCallback(async (threadName, messageArray, threadUid) => {
        if (!threadUid) {
            throw new Error('Thread UID is required to save a thread.');
        }


        try {
            const response = await fetch(`${API_BASE_URL}/save_conversation`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    thread_uid: threadUid, // Use thread_uid instead of thread_id
                    thread_name: threadName || 'New Chat',
                    messages: messageArray,
                    paste_counter: messageArray.pastes?.length || 0,
                    selected_model: currentlySelectedModel,
                }),
            });

            if (!response.ok) {
                const error = await response.json();
                console.error('useChat: Error response from server:', error);
                throw {
                    error: error.error,
                    message: error.message,
                    code: error.code,
                    status: response.status
                };
            }

            const savedData = await response.json();
            return savedData;
        } catch (error) {
            console.error('useChat: Failed to save thread:', error);
            throw error;
        }
    }, [currentlySelectedModel]);

    const createNewChat = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/create_thread`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: 'New Chat' }),
            });

            const data = await response.json();

            if (data.thread_uid) {
                setCurrentThreadId(data.thread_uid);
                setThreads(prev => {
                    const updated = new Map(prev);
                    updated.set(data.thread_uid, {
                        thread_uid: data.thread_uid,
                        name: "New Chat",
                        messages: []
                    });
                    return updated;
                });
                return data.thread_uid;
            } else {
                console.error('useChat: createNewChat: Failed to create new thread');
                return null;
            }
        } catch (error) {
            console.error('useChat: createNewChat: Error:', error);
            return null;
        }
    }, [setCurrentThreadId, setThreads]);

     /**
     * Sends a message to the LLM API, handles response, and manages state updates.
     *
     * @param {string} inputMessageContent The content of the user's message.
     * @param {string} threadId - The ID of the thread, used in the atomic messaging function
     * @returns {Promise<string|null>} - Returns threadId or null on error
     */
    const handleSendMessage = useCallback(async (inputMessageContent, threadId) => {
        if (!threads) {
            console.error('useChat: Threads map is not initialized');
            return null;
        }

        // Use provided threadId or fallback to current thread ID
        const threadToUse = threadId || currentThreadId;

        if (!inputMessageContent.trim() && pastes.length === 0) {
            return null;
        }

        // Ensure that each paste content is a string to prevent type errors
        let pastesContent = pastes.map((paste) => {
            // Handle null or undefined paste
            if (!paste) return '';

            // If paste is directly a string
            if (typeof paste === 'string') return paste;

            // If paste has content property
            if (paste.content) {
                if (typeof paste.content === 'string') {
                    return paste.content;
                }
                // Safely stringify objects and handle circular references
                if (typeof paste.content === 'object') {
                    try {
                        return JSON.stringify(paste.content, null, 2);
                    } catch (e) {
                        console.warn('Failed to stringify paste content:', e);
                        return String(paste.content);
                    }
                }
            }

            // Fallback to empty string if content is invalid
            return '';
        }).filter(Boolean).join('\n');

        let newMessageContent = `${pastesContent}\n${inputMessageContent}`.trim();

        // Add custom instructions for first message in thread
        if ((!threads.get(threadToUse)?.messages || threads.get(threadToUse).messages.length === 0) && customInstructions?.trim()) {
            newMessageContent = `${customInstructions.trim()}\n${newMessageContent}`;
        }

        const newMessage = {
            role: 'user',
            content: String(newMessageContent).trim(), // Ensure content is string
        };


        // Update thread messages immediately
        const updatedMessages = [...(threads.get(threadToUse)?.messages || []), newMessage];

        setThreads(prev => {
            const updated = new Map(prev);
            if (threadToUse) {
                updated.set(threadToUse, {
                    ...prev.get(threadToUse),
                    messages: updatedMessages
                });
            }
            return updated;
        });

        setIsGenerating(true);
        abortControllerRef.current = new AbortController();

        try {
            const threadId = threadToUse;
            let threadName = threads.get(threadToUse)?.name || 'New Chat';

            const response = await fetch(`${config.apiUrl}/chat`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messages: updatedMessages,
                    model: currentlySelectedModel,
                    thread_id: threadId, // Ensure thread_id is included in the request
                }),
                signal: abortControllerRef.current.signal,
            });

            if (!response.ok) {
                if (response.status === 401) {
                    onLogout();
                    return null;
                }
                const errorData = await response.json();
                 throw {
                    error: errorData.error,
                    message: errorData.message,
                    code: errorData.code,
                    status: response.status,
                    fullError: errorData
                };
            }

            const data = await response.json();

            const assistantMessage = {
                role: 'assistant',
                content: data.choices[0].message.content,
                model: currentlySelectedModel,
            };

            // Update thread with new messages
            const finalMessages = [...updatedMessages, assistantMessage];

            // Generate title if it's a new thread with a default name
            if (threadName === 'New Chat') {
                try {
                    const generatedTitle = await generateChatTitle(newMessageContent);
                    threadName = generatedTitle || 'New Chat';
            
                    setThreads(prev => {
                        const updated = new Map(prev);
                        if (threadId) {
                            updated.set(threadId, {
                                ...prev.get(threadId),
                                messages: finalMessages,
                                selectedModel: currentlySelectedModel,
                                name: threadName
                            });
                        }
                        return updated;
                    });
                } catch (titleError) {
                    console.error('useChat: handleSendMessage: Failed to generate title:', titleError);
                    // Fallback to default title or handle as needed
                    threadName = 'New Chat';
                }
            }
             else {
                setThreads(prev => {
                    const updated = new Map(prev);
                    if (threadId) {
                        updated.set(threadId, {
                            ...prev.get(threadId),
                            messages: finalMessages,
                            selectedModel: currentlySelectedModel
                        });
                    }
                    return updated;
                });
            }


            const savedThread = await handleThreadSave(
                threadName,
                finalMessages,
                threadId
            );


            // Clear pastes after successful message
            setPastes([]);

            return threadId;

        } catch (error) {
             if (error.name === 'AbortError') {
            } else {
                console.error('useChat: handleSendMessage: Error in message handling:', {
                    error: error.error || error.message,
                    code: error.code,
                    phase: 'message processing',
                    threadId: currentThreadId,
                    fullError: error
                });

               if (error.code === 'SUBSCRIPTION_REQUIRED') {
                   setError({
                       error: error.error,
                       message: error.message,
                       code: error.code
                   });
               } else if (error.error) {
                   setError({
                       error: error.error,
                       message: error.message || error.error,
                       code: error.code || 'API_ERROR',
                       fullError: error.fullError
                   });
               } else {
                    setError({
                       error: 'Failed to process request',
                       message: error.message || 'An unknown error occurred',
                       code: 'INTERNAL_ERROR'
                   });
               }
            }
             return null;
        } finally {
            setIsGenerating(false);
            abortControllerRef.current = null;
        }
    }, [
        threads,
        pastes,
        customInstructions,
        setThreads,
        setThreadOrder,
        currentlySelectedModel,
        onLogout,
        setPastes,
        handleThreadSave,
        setError,
        generateChatTitle,
        currentThreadId
    ]);
    
    /**
     * Sends a message to the LLM API, handles response, and manages state updates atomically.
     *
     * @param {string} threadId The ID of the thread to send the message to.
     * @param {string} messageContent The content of the user's message.
     * @returns {Promise<string|null>} Resolves with the threadId or null if an error occurs.
     */
    const sendMessageAtomic = async (threadId, messageContent) => {
        
        if (!messageContent?.trim() && pastes.length === 0) {
            return null;
        }

        // Prepare message content including pastes
        let pastesContent = pastes.map(paste => 
            typeof paste === 'string' ? paste : 
            paste?.content || ''
        ).filter(Boolean).join('\n');

        let fullMessageContent = `${pastesContent}\n${messageContent}`.trim();

        // Add custom instructions for first message
        if ((!threads.get(threadId)?.messages?.length) && customInstructions?.trim()) {
            fullMessageContent = `${customInstructions.trim()}\n${fullMessageContent}`;
        }

        const newMessage = {
            role: 'user',
            content: fullMessageContent,
        };

        try {
            // Update messages immediately for UI
            const updatedMessages = [...(threads.get(threadId)?.messages || []), newMessage];
            
            setThreads(prev => {
                const updated = new Map(prev);
                if (threadId) {
                    updated.set(threadId, {
                        ...prev.get(threadId),
                        messages: updatedMessages
                    });
                }
                return updated;
            });

            // Send to API
            const response = await fetch(`${config.apiUrl}/chat`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    messages: updatedMessages,
                    model: currentlySelectedModel,
                    thread_id: threadId,
                }),
            });

            if (!response.ok) {
                 const errorData = await response.json();
                throw {
                    error: errorData.error,
                    message: errorData.message,
                    code: errorData.code,
                    status: response.status,
                    fullError: errorData
                };
            }

            const data = await response.json();
            const assistantMessage = {
                role: 'assistant',
                content: data.choices[0].message.content,
                model: currentlySelectedModel,
            };

            // Update with assistant response
            const finalMessages = [...updatedMessages, assistantMessage];
            
            setThreads(prev => {
                const updated = new Map(prev);
                if (threadId) {
                    updated.set(threadId, {
                        ...prev.get(threadId),
                        messages: finalMessages,
                        selectedModel: currentlySelectedModel
                    });
                }
                return updated;
            });

             // Clear pastes after success
             setPastes([]);

            return threadId;
        } catch (error) {
            console.error('useChat: Error in sendMessageAtomic:', error);
            throw error;
        }
    };

    /**
     * Stops the ongoing message generation
     */
    const handleStopGeneration = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            setIsGenerating(false);
        }
    }, []);

     useEffect(() => {
        if (!currentThreadId || !threads?.size) return;

        const thread = threads.get(currentThreadId);
        if (!thread) return;

        // This effect is only concerned with preventing double loading
        // The thread loading itself is handled in useThreads
    }, [currentThreadId, threads]);

    return {
        messages,
        inputMessage,
        setInputMessage,
        isGenerating,
        error,
        handleSendMessage,
        handleStopGeneration,
        setError,
        createNewChat,
        sendMessageAtomic // add the new function to the returned object
    };
};

export default useChat;
