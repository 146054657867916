import React from 'react';

const StatCard = ({ title, value, icon: Icon }) => {
  return (
    <div className="bg-[#1a1a1a] 
      rounded-xl 
      border border-[#2a2a2a] 
      overflow-hidden 
      transition-all 
      duration-300 
      hover:border-[#3a3a3a] 
      hover:shadow-md 
      group"
    >
      <div className="flex items-center p-4 space-x-4">
        <div className="p-3 bg-orange-500/10 rounded-lg">
          <Icon className="w-6 h-6 text-orange-500 
            transition-transform 
            duration-300 
            group-hover:scale-110" 
          />
        </div>
        
        <div className="flex-grow">
          <p className="text-sm text-gray-400 
            transition-colors 
            duration-300 
            group-hover:text-gray-300"
          >
            {title}
          </p>
          <p className="text-xl font-semibold text-gray-300 
            transition-colors 
            duration-300 
            group-hover:text-white"
          >
            {value}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
