import React, { useEffect, useState, useRef, useCallback } from 'react';
import { MessageCircle, History, ArrowRight, ChevronDown } from 'lucide-react';
import MessageInput from './MessageInput';
import ThreadSidebar from '../Sidebar/ThreadSidebar';
import { API_BASE_URL } from '../../services/api';
import useSettings from '../../hooks/useSettings';

const ChatLanding = ({
  onNewChat,
  threads,
  threadOrder,
  onThreadSelect,
  inputMessage,
  setInputMessage,
  onSendMessage,
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental,
  pastes,
  onDeletePaste,
  onFileUpload,
  onAddPaste,
  sidebarPosition = 'left',
  isSidebarOpen,
  onToggleSidebar,
  onLogout,
  userMenuContainerRef,
  handleMouseLeaveSidebar,
  hasMore,
  loadMoreThreads,
  onEditThread,
  onDeleteThread,
  handleThreadChangeWrapper,
  threadsLoading,
  createNewChat,
  setCurrentlySelectedModel,
  setSidebarPosition,
  navigate, // Added navigate here
  isLoadingMore
}) => {
  const [isLoadingMoreState, setIsLoadingMoreState] = useState(false);

  // Get the 5 most recent threads
  const recentThreads = threadOrder
    ?.slice(0, 5)
    .map(id => threads?.get(id))
    .filter(Boolean);

  // Initialize settings hook
  const {
    customInstructions,
    handleSaveSettings,
    fetchSettings,
  } = useSettings({
    onLogout,
    defaultModel: currentlySelectedModel,
  });

  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Fetch settings on mount
  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const models = [
    { value: 'gpt-4o-mini', label: 'GPT-4O Mini' },
    { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-11-20' },
    { value: 'deepseek-chat', label: 'DeepSeek Chat' },
    { value: 'deepseek-reasoner', label: 'DeepSeek Reasoner' },
    { value: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku 2024-10-22' },
    { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet Latest' },
    { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29' },
    { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07' },
    { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash Exp' },
    { value: 'gemini-2.0-flash-thinking-exp', label: 'Gemini 2.0 Flash Thinking Exp' },
    { value: 'gemini-exp-1206', label: 'Gemini Exp 1206  (Gemini 2 Pro?)' },
    { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest' },
    { value: 'gemini-1.5-flash-8b', label: 'Gemini 1.5 Flash 8B' },
    { value: 'gemini-1.5-pro-latest', label: 'Gemini 1.5 Pro Latest' },
    { value: 'reka-edge-20240208', label: 'Reka Edge' },
    { value: 'reka-flash-20240904', label: 'Reka Flash' },
    { value: 'reka-core-20240904', label: 'Reka Core' },
    { value: 'command-r7b-12-2024', label: 'Command R7B 12-2024' },
    { value: 'command-r', label: 'Command R' },
    { value: 'command-r-plus-08-2024', label: 'Command R Plus 08-2024' },
    { value: 'grok-beta', label: 'Grok Beta' },
    { value: 'Meta-Llama-3.1-405B-Instruct', label: 'Meta Llama 3.1 405B Instruct' },
    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
    { value: 'chatgpt-4o-latest', label: 'ChatGPT-4O Latest' },
    { value: 'o1-mini', label: 'o1 Mini' },
    { value: 'o1', label: 'o1' },
  ];

  // Handle model selection with save
  const handleModelSelect = async (modelValue) => {
    try {
      setCurrentlySelectedModel(modelValue);
      setIsModelDropdownOpen(false);

      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: modelValue,
        sidebarPosition: sidebarPosition,
      });
    } catch (error) {
      console.error('Failed to save model selection:', error);
    }
  };

    // Handle sidebar position toggle
    const handleSidebarPositionToggle = async (newPosition) => {
        try {
            setSidebarPosition(newPosition);
            await handleSaveSettings({
                customInstructions: customInstructions || '',
                currentlySelectedModel: currentlySelectedModel,
                sidebarPosition: newPosition,
            });
        } catch (error) {
            console.error('Failed to save sidebar position:', error);
        }
    };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Get current model label
  const getCurrentModelLabel = () => {
    const model = models.find(m => m.value === currentlySelectedModel);
    return model ? model.label : 'Select Model';
  };

  // Handle mobile sidebar body class
  useEffect(() => {
    if (isSidebarOpen && window.innerWidth <= 450) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }

    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, [isSidebarOpen]);

    // Update loadMoreThreads to handle isLoadingMore
  const handleLoadMore = async () => {
    setIsLoadingMoreState(true);
    await loadMoreThreads(() => setIsLoadingMoreState(false));
  };

// Update just this function in the ChatLanding component
const handleNewChatAndMessage = async (msg) => {
  try {
      // Just send the message - the backend will create a new thread if needed
      const response = await onSendMessage(msg);
      return response;
  } catch (error) {
      return null;
  }
};



  const renderSidebar = () => {
      return (
          <ThreadSidebar
              position={sidebarPosition}
              threads={threads}
              threadOrder={threadOrder}
              currentThreadId={0}
              onThreadChange={onThreadSelect}
              onEditThread={onEditThread}
              onDeleteThread={onDeleteThread}
              onNewChat={onNewChat}
              isOpen={isSidebarOpen}
              onMouseLeave={handleMouseLeaveSidebar}
              hasMore={hasMore}
              onLoadMore={handleLoadMore}
              isLoading={threadsLoading}
              isLoadingMore={isLoadingMoreState}
              onToggleSidebar={onToggleSidebar}
              onLogout={onLogout}
              userMenuContainerRef={userMenuContainerRef}
              className={`absolute top-0 bottom-0 ${sidebarPosition === 'left' ? 'left-0' : 'right-0'}`}
          />
      );
  };

  return (
    <div className="flex flex-col h-screen bg-[#1a1a1a] text-white relative overflow-hidden">
        {isSidebarOpen && (
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-10 sm:hidden"
                onClick={onToggleSidebar}
            />
        )}

      <div className={`
        flex-1 relative flex flex-col
        transition-all duration-300
      `}>
          {/* Render the sidebar here so it's rendered above the content area on mobile */}
          {renderSidebar()}

        <div className={`
            flex-grow flex justify-center overflow-hidden relative
            ${isSidebarOpen && sidebarPosition === 'left' ? 'sm:ml-72' : 'ml-0'}
            ${isSidebarOpen && sidebarPosition === 'right' ? 'sm:mr-72' : 'mr-0'}
        `}>
          <div className={`
            flex flex-col flex-grow min-h-0 transition-all duration-300
            w-full
            sm:max-w-[1000px]
            px-2 sm:px-4
          `}>
            {/* Hero Section */}
            <div className="flex flex-col items-center justify-center h-full w-full px-2 sm:px-4">
              <div className="text-center space-y-4 mb-8 w-full">
                <h1 className="text-3xl sm:text-4xl font-bold text-gray-100">
                  Start a New Conversation
                </h1>
                <p className="text-sm sm:text-base text-gray-400 px-2">
                  Ask me anything - I'm here to help with coding, analysis, writing, and more
                </p>
              </div>

              {/* Center - Model Selector */}
                <div className="relative mx-4 min-w-[120px] max-w-[280px] flex-shrink mb-8" ref={dropdownRef}>
                    <button
                        onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
                        className="w-full flex items-center justify-between px-4 py-2 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a] transition-colors"
                        aria-label="Select Model"
                        title="Select Model"
                    >
                        <span className="text-sm font-medium truncate pr-2">
                            {getCurrentModelLabel()}
                        </span>
                        <ChevronDown
                            size={16}
                            className={`transform transition-transform duration-200 flex-shrink-0 ${isModelDropdownOpen ? 'rotate-180' : ''}`}
                        />
                    </button>

                    {isModelDropdownOpen && (
                        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 py-2 bg-[#2a2a2a] rounded-lg shadow-xl z-50 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full">
                            {models.map((model) => (
                                <button
                                    key={model.value}
                                    onClick={() => handleModelSelect(model.value)}
                                    className={`w-full text-left px-4 py-2 text-sm hover:bg-[#3a3a3a] transition-colors ${
                                        currentlySelectedModel === model.value ? 'bg-orange-500 text-white' : 'text-gray-200'
                                    }`}
                                    title={`Select ${model.label}`}
                                >
                                    {model.label}
                                </button>
                            ))}
                        </div>
                    )}
                </div>


              {/* Message Input Section */}
              <div className="w-full max-w-2xl mb-8 sm:mb-12 px-2">
                <MessageInput
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  onSendMessage={handleNewChatAndMessage}
                  isGenerating={false}
                  pastes={pastes}
                  onDeletePaste={onDeletePaste}
                  onFileUpload={onFileUpload}
                  onAddPaste={onAddPaste}
                  currentlySelectedModel={currentlySelectedModel}
                    experimentalFeatures={experimentalFeatures}
                    onToggleExperimental={onToggleExperimental}
                />
              </div>

              {/* Recent Chats Section */}
              <div className="w-full max-w-2xl px-2">
                <div className="flex items-center gap-2 mb-4">
                  <History className="text-orange-500" size={20} />
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-100">Recent Conversations</h2>
                </div>
                {recentThreads?.length > 0 ? (
                  <div className="space-y-2">
                    {recentThreads.map(thread => (
                      <button
                        key={thread.id}
                        onClick={() => {
                          onThreadSelect(thread.id);
                        }}
                        className="w-full p-3 sm:p-4 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a]
                          transition-colors duration-200 group text-left flex
                          items-center justify-between"
                      >
                        <div className="flex items-center gap-2 sm:gap-3">
                          <MessageCircle
                            size={16}
                            className="text-gray-500 group-hover:text-orange-500"
                          />
                          <div>
                            <h3 className="text-sm sm:text-base text-gray-200 font-medium">
                              {thread.name}
                            </h3>
                              {thread.preview && (
                                  <p className="text-xs sm:text-sm text-gray-400 truncate max-w-[200px] sm:max-w-md">
                                      {thread.preview}
                                  </p>
                              )}
                          </div>
                        </div>
                        <ArrowRight
                          size={16}
                          className="text-gray-500 group-hover:text-orange-500
                            opacity-0 group-hover:opacity-100 transition-opacity"
                        />
                      </button>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-6 sm:py-8 text-sm sm:text-base text-gray-400">
                    No conversations loaded yet - start a new chat above!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatLanding;
