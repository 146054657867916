import { useState, useCallback } from 'react';
import { sanitizeFileName } from '../utils/utils';

export const modelsWithImage = [
  { value: 'reka-core-20240904', label: 'Reka Core 2024-09-04' },
  { value: 'reka-flash-20240904', label: 'Reka Flash 2024-09-04' },
  { value: 'reka-edge-20240208', label: 'Reka Edge 2024-02-08' },
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29' },
  { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet latest' },
  { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07' },
  { value: 'claude-3-5-sonnet-latest', label: 'Claude 3.5 Sonnet latest' },
  { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest' },
  { value: 'gemini-1.5-flash-8b', label: 'Gemini 1.5 Flash 8B' },
  { value: 'gemini-1.5-pro-latest', label: 'Gemini 1.5 Pro Latest' },
  { value: 'gemini-exp-1206', label: 'Gemini Exp 1206' },
  { value: 'gemini-2.0-flash-exp', label: 'Gemini 2.0 Flash Exp' },
  { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-08-06' },
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo' },
  { value: 'gpt-4o-mini', label: 'GPT-4O Mini' },
  { value: 'chatgpt-4o-latest', label: 'ChatGPT-4O Latest' },
];
export const modelsWithAudio = [
  { value: 'reka-core-20240904', label: 'Reka Core 2024-09-04' },
  { value: 'reka-flash-20240904', label: 'Reka Flash 2024-09-04' },
  { value: 'reka-edge-20240208', label: 'Reka Edge 2024-02-08' },
];
/**
 * Checks if the given model supports image uploads
 * @param {string} modelId - The model identifier to check
 * @returns {boolean} - Whether the model supports images
 */
const isModelWithImageSupport = (modelId) => {
  if (!modelId) return false;

  return modelsWithImage.some(model => 
    model.value === modelId || 
    modelId.includes('sonnet-latest') ||
    modelId === 'claude-3-5-sonnet-latest'
  );
};

/**
 * Custom hook to manage pastes and file uploads.
 * @returns {Object} - Contains pastes state and related actions.
 */
const usePastes = () => {
  const [pastes, setPastes] = useState([]);

  /**
   * Deletes a paste at the specified index.
   * @param {number} index - The index of the paste to delete.
   */
  const handleDeletePaste = useCallback((index) => {
    setPastes((prevPastes) => prevPastes.filter((_, i) => i !== index));
  }, []);

  /**
   * Handles file uploads, supporting text-based files and conditionally supporting images based on model
   * Processes uploaded files, converts them to appropriate format (text or base64 for images),
   * and adds them to the pastes state with proper metadata
   * @param {Event} event - The file input change event
   * @param {string} currentModel - The currently selected model ID
   */
  const handleFileUpload = useCallback((event, currentModel) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const isImageSupportedModel = isModelWithImageSupport(currentModel);

    files.forEach((file) => {
        const reader = new FileReader();
        const supportedTypes = [
            'text/plain',
            'application/json',
            'text/markdown',
            'text/xml',
            'application/xml',
            'text/csv',
            'application/javascript',
            'text/javascript',
            'application/python',
            'text/x-python',
            ...(isImageSupportedModel ? [
                'image/png',
                'image/webp',
                'image/jpeg',
                'image/jpg'
            ] : [])
        ];

        const supportedExtensions = [
            'js', 
            'md',
            ...(isImageSupportedModel ? ['png', 'webp', 'jpeg', 'gif'] : [])
        ];

        if (supportedTypes.includes(file.type) || supportedExtensions.some(ext => file.name.endsWith(`.${ext}`))) {
            const isImageFile = file.type.startsWith('image/');

            if (isImageFile && !isImageSupportedModel) {
                console.error(`Images are not supported for the current model "${currentModel}"`);
                return;
            }

            reader.onload = (e) => {
                const content = e.target.result;
                const sanitizedFileName = sanitizeFileName(file.name);

                if (isImageFile) {
                    // Handle images with new format
                    const base64Data = content.split(',')[1];
                    const imageContent = `[!image]{${sanitizedFileName}}|${base64Data}|[/!image]`;
                    setPastes((prevPastes) => [...prevPastes, {
                        content: imageContent, // Match original structure
                        fileName: sanitizedFileName,
                        type: 'image'
                    }]);
                } else {
                    // Keep original file format exactly as it was
                    const fileTag = `<${sanitizedFileName}>${content}</${sanitizedFileName}>\n`;
                    setPastes((prevPastes) => [...prevPastes, {
                        content: fileTag,
                        fileName: sanitizedFileName,
                        type: 'text'
                    }]);
                }
            };

            if (isImageFile) {
                reader.readAsDataURL(file);
            } else {
                reader.readAsText(file);
            }

            reader.onerror = () => {
                console.error(`Failed to read the file "${file.name}".`);
            };
        } else {
            console.error(
                `Unsupported file type for "${file.name}". Only text-based files${
                    isImageSupportedModel ? ' and images' : ''
                } are supported.`
            );
        }
    });
    event.target.value = null;
}, []);

  /**
   * Adds a new paste to the pastes state.
   * @param {Object} newPaste - The new paste object to add.
   */
  const handleAddPaste = useCallback((newPaste) => {
    // If it's an image paste, ensure it's in the correct format and don't wrap in XML
    if (newPaste.type === 'image') {
      if (!newPaste.content.includes('[!image]')) {
        const base64Data = newPaste.content.split(',')[1] || newPaste.content;
        const formattedContent = `[!image]{${newPaste.fileName}}|${base64Data}|[/!image]`;
        
        setPastes((prevPastes) => [...prevPastes, {
          ...newPaste,
          content: formattedContent,
          isImage: true // Add this flag
        }]);
      } else {
        // Content is already in correct format
        setPastes((prevPastes) => [...prevPastes, {
          ...newPaste,
          isImage: true // Add this flag
        }]);
      }
    } else {
      // For non-image pastes, keep the XML wrapping
      setPastes((prevPastes) => [...prevPastes, newPaste]);
    }
  }, []);
  
  return {
    pastes,
    setPastes,
    handleDeletePaste,
    handleFileUpload,
    handleAddPaste,
  };
};

export default usePastes;
