// components/blog/Blog.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Share2 } from 'lucide-react';
import The_Window_After_o3 from './posts/The_Window_After_o3.js';

// Posts configuration object with metadata and components
const POSTS = {
  'the-window-after-o3': {
    id: 'the-window-after-o3',
    title: 'The Window After o3',
    date: '2025-01-15',
    readTime: '8 min',
    ContentComponent: The_Window_After_o3,
    description: 'Exploring the implications of the coming professional AI transition',
  }
  // Add more posts here as needed
};

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [showShareNotification, setShowShareNotification] = useState(false);

  const handleCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowShareNotification(true);
    setTimeout(() => setShowShareNotification(false), 2000);
  };

  // Effect to update URL when post is selected
  useEffect(() => {
    if (selectedPost) {
      window.history.pushState({}, '', `/blog/${selectedPost.id}`);
    } else {
      window.history.pushState({}, '', '/blog');
    }
  }, [selectedPost]);

  // Effect to handle direct navigation to post URLs
  useEffect(() => {
    const path = window.location.pathname;
    const postId = path.split('/blog/')[1];
    if (postId && POSTS[postId] && !selectedPost) {
      setSelectedPost(POSTS[postId]);
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-[#1a1a1a] text-white">
      {/* Header */}
      <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 border-b border-[#2a2a2a]">
        <div className="flex items-center">
          <Link
            to="/"
            className="hidden lg:flex items-center cursor-pointer ml-2"
          >
            <img src="/output.webp" alt="Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold">henosis</div>
          </Link>
        </div>

        <div className="flex items-center space-x-4">
          <Link
            to="/chat"
            className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
          >
            Chat
          </Link>
          <Link
            to="/blog"
            className="px-4 py-2 text-purple-400 border-b-2 border-purple-500"
          >
            Blog
          </Link>
        </div>
      </header>

      <main className="flex-grow flex justify-center px-4 py-8">
        <div className="max-w-3xl w-full">
          {selectedPost ? (
            // Single Post View
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setSelectedPost(null)}
                  className="flex items-center text-gray-400 hover:text-white transition-colors"
                >
                  <ArrowLeft size={20} className="mr-2" />
                  Back to Posts
                </button>
                <div className="relative">
                  <button
                    onClick={handleCopyShareLink}
                    className="p-2 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
                    title="Share Post"
                  >
                    <Share2 size={20} />
                  </button>
                  {showShareNotification && (
                    <div className="absolute top-full right-0 mt-2 px-3 py-1 bg-purple-600 text-white text-sm rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                </div>
              </div>

              <article className="prose prose-invert prose-purple max-w-none">
                <div className="flex items-center text-gray-400 mb-8 space-x-4">
                  <span className="flex items-center">
                    <Calendar size={16} className="mr-2" />
                    {selectedPost.date}
                  </span>
                  <span className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {selectedPost.readTime}
                  </span>
                </div>
                <div className="leading-relaxed text-gray-200">
                  <selectedPost.ContentComponent />
                </div>
              </article>
            </div>
          ) : (
            // Posts List View
            <div className="space-y-8">
              <h1 className="text-4xl font-bold mb-8">Blog Posts</h1>
              <div className="grid gap-6">
                {Object.values(POSTS).map((post) => (
                  <div
                    key={post.id}
                    className="bg-[#2a2a2a] rounded-lg p-6 hover:bg-[#3a3a3a] transition-colors cursor-pointer"
                    onClick={() => setSelectedPost(post)}
                  >
                    <h2 className="text-2xl font-semibold mb-3 text-purple-400">
                      {post.title}
                    </h2>
                    <p className="text-gray-300 mb-4">{post.description}</p>
                    <div className="flex items-center text-gray-400 space-x-4">
                      <span className="flex items-center">
                        <Calendar size={16} className="mr-2" />
                        {post.date}
                      </span>
                      <span className="flex items-center">
                        <Clock size={16} className="mr-2" />
                        {post.readTime}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Blog;
