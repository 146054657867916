// src/components/Pricing/PricingData.js
import { Sparkles, Building2, Rocket, Briefcase, Star, Crown } from 'lucide-react';
import React from 'react';

export const pricingTiers = [
  {
    name: 'Personal',
    monthlyPrice: 10,
    yearlyPrice: 8, // per month when paid yearly
    icon: <Sparkles className="w-6 h-6 text-gray-400" />,
    description: 'Basic access to chat with Standard Usage',
    features: [
      { name: 'Standard Usage Limits', included: true},
      { name: 'Access to All Models', included: true },
      { name: 'Full Context Windows', included: true },
      { name: 'Multi-Modal Input', included: true },
      { name: 'Unlimited Files / Context', included: true },
      { name: 'Model Swapping Within a Chat', included: true },
      { name: 'Web Search with GPT models', included: true },
      { name: 'Image Generation', included: true },
    ],
    buttonText: 'Get Started',
    buttonVariant: 'secondary',
    tier: 'top'
  },
  {
    name: 'Personal+',
    monthlyPrice: 40,
    yearlyPrice: 32, // per month when paid yearly
    icon: <Rocket className="w-6 h-6 text-orange-500" />,
    description: 'Full access to all models and features',
    features: [
      { name: '4x Usage Limits', included: true},
      { name: 'Access to All Models', included: true },
      { name: 'Multi-Modal Input', included: true },
      { name: 'Unlimited Files / Context', included: true },
      { name: 'Model Swapping Within a Chat', included: true },
      { name: 'Web Search with GPT models', included: true },
      { name: 'Projects', included: true },
      { name: 'Image Generation', included: true },
    ],
    buttonText: 'Start Pro',
    buttonVariant: 'primary',
    highlight: true,
    tier: 'top'
  },
  {
    name: 'Pro',
    monthlyPrice: 90,
    yearlyPrice: 72, // per month when paid yearly
    icon: <Building2 className="w-6 h-6 text-gray-400" />,
    description: 'Professionals & Super Users',
    features: [
      { name: '9x Usage Limits', included: true },
      { name: 'Everything in Personal+', included: true },
      { name: 'Full Context Windows', included: true },
      { name: 'Advanced Analytics', included: true },
      { name: 'Priority Support', included: true },
      { name: 'Projects', included: true },
      { name: 'Model Swapping Within a Chat', included: true },
      { name: 'Image Generation', included: true },
    ],
    buttonText: 'Start Business',
    buttonVariant: 'secondary',
    tier: 'top'
  },
  {
    name: 'Team',
    monthlyPrice: 200,
    yearlyPrice: 160, // per month when paid yearly
    icon: <Briefcase className="w-6 h-6 text-purple-500" />,
    description: 'Perfect for growing teams',
    features: [
      { name: 'Everything in Pro', included: true },
      { name: '15 Team Members', included: true },
      { name: 'Advanced Team Analytics', included: true },
      { name: 'Custom Projects', included: true },
      { name: 'Team Training Sessions', included: true },
      { name: '20x Usage Limits', included: true },
      { name: 'API Access', included: true },
      { name: 'Custom Tool Requests', included: true },
    ],
    buttonText: 'Start Team',
    buttonVariant: 'secondary',
    tier: 'bottom'
  },
  {
    name: 'Team+',
    monthlyPrice: 400,
    yearlyPrice: 320, // per month when paid yearly
    icon: <Star className="w-6 h-6 text-blue-500" />,
    description: 'For large organizations',
    features: [
      { name: 'Everything in Team', included: true },
      { name: '50 Team Members', included: true },
      { name: 'Enterprise Analytics', included: true },
      { name: 'Custom Integration', included: true },
      { name: 'Premium API Access', included: true },
      { name: '40x Usage', included: true },
      { name: 'Custom Security Controls', included: true },
      { name: 'Priority Support', included: true },
    ],
    buttonText: 'Contact Sales',
    buttonVariant: 'primary',
    tier: 'bottom'
  },
  {
    name: 'Enterprise',
    monthlyPrice: null,
    yearlyPrice: null,
    icon: <Crown className="w-6 h-6 text-yellow-500" />,
    description: 'Custom enterprise solutions',
    features: [
      { name: 'Everything in Enterprise', included: true },
      { name: 'Team groups', included: true },
      { name: 'Custom SLAs', included: true },
      { name: 'Dedicated Support', included: true },
      { name: 'Custom Feature Development', included: true },
      { name: 'Enterprise Compliance', included: true },
      { name: 'Advanced Data Controls', included: true },
      { name: 'Custom Projects', included: true },
    ],
    buttonText: 'Contact Sales',
    buttonVariant: 'secondary',
    tier: 'bottom'
  }
];

export const getPlanFeatures = (tierName) => {
    // Remove " Monthly" or " Yearly" from the tier name to match PricingData
    const cleanedTierName = tierName 
        ? tierName.replace(" Monthly", "").replace(" Yearly", "") 
        : null;

    // Special case for Test User
    if (cleanedTierName === 'Test User') {
        return [
            { name: '9x Usage Limits', included: true },
            { name: 'Everything in Personal+', included: true },
            { name: 'Full Context Windows', included: true },
            { name: 'Advanced Analytics', included: true },
            { name: 'Priority Support', included: true },
            { name: 'Projects', included: true },
            { name: 'Model Swapping Within a Chat', included: true },
            { name: 'Image Generation', included: true },
          ];
    }

    // Find the tier in pricingTiers
    const foundTier = pricingTiers.find(tier => tier.name === cleanedTierName);

    // Return features if found, otherwise return default
    return foundTier 
        ? foundTier.features 
        : [{ name: '- Chat History', included: true }];
};
