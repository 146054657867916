// src/services/api.js
import config from '../config';

export const API_BASE_URL = config.apiUrl;

/**
 * Handles the API response, parsing JSON and handling errors.
 * Also processes any message field in the JSON response.
 *
 * @param {Response} response - The fetch Response object
 * @returns {Promise<Object|string>} - Parsed data or text content
 * @throws {Error} - If there's an error in the response or parsing
 */
const handleResponse = async (response) => {
    const text = await response.text();
    try {
        const data = JSON.parse(text);
        if (!response.ok) {
            // Create an error object that includes all relevant fields
            const errorData = {
                error: data.error || 'An unknown error occurred',
                message: data.message || null,
                code: data.code || null
            };
            throw errorData; // Throw the entire error object
        }
        // Handle message field if present
        if (data.message) {
            console.log('Message from server:', data.message);
        }
        return data;
    } catch (error) {
        if (response.ok) {
            return text;
        }
        // If it's our custom error object, throw it directly
        if (error.error && error.message) {
            throw error;
        }
        throw new Error(error.message || 'Failed to parse response');
    }
};

/**
 * Saves a conversation to the server.
 * Handles both new and existing threads with proper validation.
 * 
 * @param {string} threadName - The name of the thread
 * @param {Array} messages - The messages in the conversation
 * @param {string|null} threadUid - The UUID of the thread (null for new threads)
 * @param {string} selectedModel - The selected AI model
 * @param {Object} shareSettings - Optional sharing configuration
 * @returns {Promise} - The server response with thread_uid
 */
export const saveConversation = async (threadName, messages, threadUid = null, selectedModel, shareSettings = null) => {
  const payload = {
    thread_name: threadName || 'New Chat',
    messages,
    paste_counter: messages.pastes?.length || 0,
    selected_model: selectedModel || null,
    is_new_thread: !threadUid  // Set based on whether we have a threadUid
  };

  // Add thread identifier if exists
  if (threadUid) {
    payload.thread_uid = threadUid;
  }

  // Add share settings if provided
  if (shareSettings) {
    payload.share_settings = shareSettings;
  }

  console.log('Saving conversation with payload:', payload);

  const response = await fetch(`${API_BASE_URL}/save_conversation`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return handleResponse(response);
};


/**
 * Generates a concise chat title based on the initial query.
 * Includes enhanced error handling, logging, and fallback behavior.
 *
 * @param {string} initialQuery - The initial user query.
 * @returns {Promise<string>} - A chat title (max 5 words) or fallback title.
 */
export const generateChatTitle = async (initialQuery) => {
    console.log('generateChatTitle: Starting with full configuration:', {
        initialQuery: initialQuery.substring(0, 100) + '...',
        API_BASE_URL,
        timestamp: new Date().toISOString()
    });

    // System prompt for title generation
    const systemPrompt = `Your job is to create a short and safe chat title for the thread based on the initial query.
    Requirements:
    - Maximum 5 words
    - Avoid any potentially offensive or inappropriate language
    - Replace inappropriate content with positive alternatives
    - Make it friendly and welcoming
    - Keep it concise and clear
    - Focus on the main topic
    - Don't include sensitive information
    
    Example good titles:
    - "Code Review Discussion"
    - "Python Learning Help"
    - "Website Design Planning"
    
    Example bad titles:
    - "Complex Discussion About Multiple Topics" (too long)
    - "????" (too vague)
    - "Query About Something" (not specific enough)
    
    Generate a title for this query.`;

    const messagesForTitle = [
        { role: 'user', content: `${systemPrompt}\n\n${initialQuery}` },
    ];

    try {
        console.log('generateChatTitle: Preparing request with payload:', {
            messages: messagesForTitle,
            model: 'gemini-1.5-flash-8b',
            is_title_generation: true
        });

        const response = await fetch(`${API_BASE_URL}/chat`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: messagesForTitle,
                model: 'gemini-1.5-flash-8b',
                is_title_generation: true,
            }),
        });

      console.log('generateChatTitle: Raw response:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers.entries())
        });

       if (!response.ok) {
          console.error('generateChatTitle: API request failed:', {
              status: response.status,
             statusText: response.statusText,
              body: await response.text()
          });
            return 'New Chat';
        }

       const data = await response.json();
       console.log('generateChatTitle: Parsed API response:', data);

       let title = '';
       if (data?.choices?.[0]?.message?.content) {
            title = data.choices[0].message.content.trim();
           console.log('generateChatTitle: Successfully extracted title:', title);
       } else {
            console.warn('generateChatTitle: Unexpected response structure:', data);
           title = 'New Chat';
       }

      // Clean and format the title
       const cleanTitle = title
            .replace(/[^\w\s-]/g, '')
           .trim();

      if (!cleanTitle) {
            console.warn('generateChatTitle: Title became empty after cleaning');
          return 'New Chat';
       }

      // Limit to 5 words
      const words = cleanTitle.split(/\s+/);
       const finalTitle = words.length > 5 ? words.slice(0, 5).join(' ') : cleanTitle;
        console.log('generateChatTitle: Final processed title:', {
             original: title,
             cleaned: cleanTitle,
           final: finalTitle
        });

        return finalTitle;

    } catch (error) {
        console.error('generateChatTitle: Error generating title:', {
            error: error.message,
           stack: error.stack,
           initialQuery: initialQuery.substring(0, 100) + '...'
       });
       return 'New Chat';
   }
};


export const fetchUsername = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/username`, {
        method: 'GET',
        credentials: 'include',
      headers: {
         'Content-Type': 'application/json',
       },
    });
    return await handleResponse(response); // Return JSON response
  } catch (error) {
    console.error('API: Error fetching username:', error);
      throw error;
  }
};

/**
 * Fetches conversations from the server based on the specified load type and parameters.
 * Includes enhanced error handling, timeouts, and detailed logging.
 * 
 * @param {string} loadType - Type of load operation ('thread' or undefined for general list)
 * @param {Object} params - Query parameters including thread_uid, offset, limit
 * @param {Object} options - Additional options like timeout duration
 * @returns {Promise<Array>} Array of threads or messages depending on loadType
 */
export const getConversations = async (loadType, params = {}, options = {}) => {
  const controller = new AbortController();
  const timeoutDuration = options.timeout || 180000; // 3 minutes default timeout
  const timeoutId = setTimeout(() => controller.abort(), timeoutDuration);

  const requestStartTime = Date.now();
  const requestId = Math.random().toString(36).substring(7);

  try {
      console.log('API: getConversations started:', {
          requestId,
          loadType,
          params,
          timeout: timeoutDuration,
          timestamp: new Date().toISOString()
      });

      const queryParams = new URLSearchParams({
          ...(loadType && { type: loadType }),
          ...(params.offset && { offset: params.offset }),
          ...(params.limit && { limit: params.limit }),
          ...(params.thread_uid && { thread_uid: params.thread_uid }),
          include_shared: params.includeShared ?? true
      });

      const endpoint = `${API_BASE_URL}/get_conversations?${queryParams}`;
      console.log('API: Fetching from endpoint:', {
          requestId,
          endpoint,
          params: queryParams.toString()
      });

      const response = await fetch(endpoint, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              'Content-Type': 'application/json',
              // Removed X-Request-ID header
          },
      });

      console.log('API: Raw response received:', {
          requestId,
          status: response.status,
          statusText: response.statusText,
          duration: `${Date.now() - requestStartTime}ms`,
          headers: Object.fromEntries(response.headers.entries())
      });

      if (!response.ok) {
          const errorText = await response.text();
          console.error('API: Error response:', {
              requestId,
              status: response.status,
              error: errorText,
              duration: `${Date.now() - requestStartTime}ms`
          });
          throw new Error(`HTTP error! status: ${response.status}, error: ${errorText}`);
      }

      const data = await response.json();

      if (loadType === 'thread' && params.thread_uid) {
          const messages = Array.isArray(data) ? data : 
                         data.messages ? data.messages :
                         data.thread?.messages ? data.thread.messages :
                         [];

          console.log('API: Thread messages processed:', {
              requestId,
              threadId: params.thread_uid,
              messageCount: messages.length,
              duration: `${Date.now() - requestStartTime}ms`
          });

          return messages;
      }

      if (data.threads || Array.isArray(data)) {
          const threads = Array.isArray(data) ? data : data.threads || [];
          
          console.log('API: Thread list processed:', {
              requestId,
              threadCount: threads.length,
              duration: `${Date.now() - requestStartTime}ms`
          });

          return threads;
      }

      console.warn('API: Unexpected response format:', {
          requestId,
          data,
          duration: `${Date.now() - requestStartTime}ms`
      });
      return [];

  } catch (error) {
      const duration = `${Date.now() - requestStartTime}ms`;

      if (error.name === 'AbortError') {
          console.error('API: Request timeout:', {
              requestId,
              timeout: timeoutDuration,
              duration,
              loadType,
              params
          });
          throw new Error(`Request timeout after ${timeoutDuration/1000} seconds`);
      }

      console.error('API: Error in getConversations:', {
          requestId,
          error: error.message,
          stack: error.stack,
          duration,
          params: {
              loadType,
              thread_uid: params.thread_uid,
              offset: params.offset,
              limit: params.limit
          }
      });

      throw error;

  } finally {
      clearTimeout(timeoutId);
      console.log('API: Request completed:', {
          requestId,
          duration: `${Date.now() - requestStartTime}ms`,
          loadType,
          params: JSON.stringify(params)
      });
  }
};

export const checkAuth = async () => {
  const response = await fetch(`${API_BASE_URL}/check-auth`, {
    method: 'GET',
     credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  await handleResponse(response);
};
/**
 * Deletes a thread by its UUID.
 * 
 * @param {string} threadUid - The UUID of the thread to delete
 * @returns {Promise} - The server response
 */
export const deleteThread = async (threadUid) => {
  const response = await fetch(`${API_BASE_URL}/delete_conversation/${threadUid}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
      },
  });
  return handleResponse(response);
};

/**
 * Fetches custom instructions, last selected model, experimental features state, and sidebar position
 * for the authenticated user.
 *
 * @returns {Promise<Object>} Object containing user preferences including sidebar position
 */
export const fetchCustomInstructions = async () => {
  console.log('Fetching user preferences from API...');
  const response = await fetch(`${API_BASE_URL}/get_custom_instructions`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
     },
  });
  const data = await handleResponse(response);
  return {
      ...data,
    sidebarPosition: data.sidebar_position || 'left' // Ensure sidebarPosition is always returned
   };
};

/**
 * Saves custom instructions, last selected model, experimental features state, and sidebar position
 * for the authenticated user.
 *
 * @param {string} instructions - The custom instructions to save
 * @param {string} lastModel - The last selected model
 * @param {boolean} experimentalFeatures - The state of experimental features
 * @param {string} sidebarPosition - The position of the sidebar ('left' or 'right')
 * @returns {Promise<Object>} The response from the server
 */
export const saveCustomInstructions = async (instructions, lastModel, experimentalFeatures, sidebarPosition) => {
  // Validate sidebar position
  if (sidebarPosition !== 'left' && sidebarPosition !== 'right') {
     throw new Error('Invalid sidebar position. Must be either "left" or "right"');
  }

    const response = await fetch(`${API_BASE_URL}/save_custom_instructions`, {
      method: 'POST',
        credentials: 'include',
       headers: {
         'Content-Type': 'application/json',
       },
      body: JSON.stringify({
            instructions: instructions?.trim() || '',
            last_model: lastModel,
            experimental_features: experimentalFeatures,
           sidebar_position: sidebarPosition,
       }),
   });

  const data = await handleResponse(response);
    console.log('Saved user preferences:', {
        instructions: instructions?.trim() || '',
        lastModel,
        experimentalFeatures,
        sidebarPosition
     });

    return data;
  };
export const getDashboardData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/dashboard_data`, {
         method: 'GET',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

       if (!response.ok) {
           throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
       console.log('Dashboard data received:', data);
         return data;
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
      throw error;
   }
};


export const updateThreadName = async (threadId, newName) => {
    const response = await fetch(`${API_BASE_URL}/update_thread_name/${threadId}`, {
       method: 'PUT',
       credentials: 'include',
       headers: {
          'Content-Type': 'application/json',
         },
      body: JSON.stringify({ name: newName }),
    });
    return handleResponse(response);
};


/**
 * Shares a thread with specific users or makes it public.
 * 
 * @param {string} threadUid - The UUID of the thread to share
 * @param {Object} shareSettings - Sharing configuration
 * @returns {Promise<Object>} Object containing share settings and status
 */
export const shareThread = async (threadUid, shareSettings) => {
  try {
    console.log('API: Sharing thread:', {
      threadUid,
      shareSettings: JSON.stringify(shareSettings)
    });

    const payload = shareSettings || {
      access_type: 'public',
      allowed_users: ['ANY'],
      permissions: ['share']
    };

    const response = await fetch(`${API_BASE_URL}/share_thread/${threadUid}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    // Log the raw response for debugging
    console.log('API: Share thread response:', {
      status: response.status,
      statusText: response.statusText
    });

    // Parse the JSON response data
    const data = await handleResponse(response);
    
    // Log the parsed data
    console.log('API: Share thread parsed data:', data);

    // Return an object with the share URL and other needed data
    return {
      shareUrl: data.share_url,
      publicId: data.public_id,
      success: data.success
    };
  } catch (error) {
    console.error('API: Error sharing thread:', {
      error: error.message,
      stack: error.stack,
      threadUid
    });
    throw error;
  }
};

/**
* Removes sharing access for a thread.
* 
* @param {string} threadUid - The UUID of the thread
* @returns {Promise} - The server response
*/
export const unshareThread = async (threadUid) => {
  try {
      const response = await fetch(`${API_BASE_URL}/unshare_thread/${threadUid}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      return handleResponse(response);
  } catch (error) {
      console.error('API: Error unsharing thread:', error);
      throw error;
  }
};

/**
 * Fetches a shared thread by its public ID
 * @param {string} publicId - The public ID of the shared thread
 * @returns {Promise<Object>} The shared thread data
 */
export const getSharedThread = async (publicId) => {
    try {
        console.log('API: Fetching shared thread:', publicId);

       const response = await fetch(`${API_BASE_URL}/shared/${publicId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
             }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

       const data = await response.json();
       console.log('API: Shared thread fetched successfully:', data);

       return data;
    } catch (error) {
        console.error('API: Error fetching shared thread:', error);
       throw error;
   }
}

export const fetchLogout = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/logout`, {
      method: 'POST',
        credentials: 'include',
        headers: {
           'Content-Type': 'application/json',
        },
         body: JSON.stringify({
            device_id: localStorage.getItem('deviceId')
          })
    });

     if (!response.ok) {
       const errorData = await response.json()
          throw new Error(errorData.error || `HTTP error! status: ${response.status}`)
    }
      return await response.json();
  } catch (error) {
    console.error('Logout error', error)
        throw error;
  }
}

export const fetchUserProfile = async () => {
    try {
        const response = await fetch(`${config.apiUrl}/profile`, {
            credentials: 'include'
        });

        if (!response.ok) {
           const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Fetch Profile Error: ', error)
        throw error;
    }
}
