import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { fetchUserProfile, fetchLogout } from '../../services/api';
import { 
  User, Shield, Calendar, Tablet, Share2, 
  BookOpen, LogOut, ArrowLeft, X, 
  MessageCircle, LayoutDashboard, CreditCard
} from 'lucide-react';

import LoadingSpinner from '../Common/LoadingSpinner';
import ErrorBoundary from '../Common/ErrorBoundary';
import ProfileHeader from './ProfileHeader';
import ProfileSection from './ProfileSection';
import StatCard from './ProfileStats';

const LogoutConfirmModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-[#2a2a2a] rounded-xl p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-300">Confirm Logout</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        
        <p className="text-gray-400 mb-6">
          Are you sure you want to log out of your account?
        </p>
        
        <div className="flex justify-end space-x-3">
          <button 
            onClick={onClose}
            className="px-4 py-2 bg-[#3a3a3a] text-gray-300 rounded-lg hover:bg-[#4a4a4a] transition-colors"
          >
            Cancel
          </button>
          <button 
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

const NavigationSidebar = () => {
    const navItems = [
      { 
        icon: MessageCircle, 
        label: 'Chat', 
        path: '/',
        iconColor: 'text-gray-400',
      },
      { 
        icon: LayoutDashboard, 
        label: 'Dashboard', 
        path: '/dashboard',
        iconColor: 'text-gray-400', 
      },
      { 
        icon: CreditCard, 
        label: 'Pricing', 
        path: '/pricing',
        iconColor: 'text-gray-400',
      },
      { 
        icon: User, 
        label: 'Profile', 
        path: '/profile',
        active: true,
        iconColor: 'text-orange-500'
      },
    ];
  
    return (
      <div className="bg-[#1a1a1a] border-r border-[#2a2a2a] w-20 py-8 flex flex-col items-center">
        {navItems.map((item, index) => (
          <Link 
            key={index} 
            to={item.path}
            className={`p-3 rounded-lg mb-4 ${
              item.active 
                ? 'bg-orange-500/20 text-orange-500' 
                : 'hover:bg-[#2a2a2a]'
            }`}
          >
            <item.icon className={item.iconColor} />
          </Link>
        ))}
      </div>
    );
  };

const Profile = () => {
  const navigate = useNavigate();
  const { verifyAuth } = useAuth();
  
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await fetchLogout();
      navigate('/login');
      window.location.reload();
    } catch (err) {
      console.error('Logout Error', err);
      setError('Failed to log out. Please try again.');
      setIsLogoutModalOpen(false);
    }
  };

  const fetchProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchUserProfile();
      setProfileData(data);
    } catch (err) {
      console.error('Profile Fetch Error', err);
      setError('Could not load profile data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!verifyAuth()) {
      navigate('/login');
      return;
    }
    fetchProfile();
  }, [verifyAuth, navigate, fetchProfile]);

  if (isLoading) return <LoadingSpinner />;
  
  if (error) {
    return (
      <div className="min-h-screen bg-[#1a1a1a] flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  const { 
    user, 
    devices = [], 
    projects = [], 
    shared_threads = [] 
  } = profileData || {};

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-[#1a1a1a] flex">
        <NavigationSidebar />
        
        <div className="flex-grow">
          {/* Logout Confirmation Modal */}
          <LogoutConfirmModal
            isOpen={isLogoutModalOpen}
            onClose={() => setIsLogoutModalOpen(false)}
            onConfirm={handleLogout}
          />

          {/* Header */}
          <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 border-b border-[#2a2a2a]">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/')}
                className="p-1.5 bg-orange-500 rounded hover:bg-orange-600 transition-colors mr-4"
                aria-label="Back"
              >
                <ArrowLeft size={15} />
              </button>
              <h1 className="text-xl font-bold text-white">Profile Settings</h1>
            </div>
          </header>

          <div className="max-w-7xl mx-auto px-4 py-8">
            <ProfileHeader 
              username={user?.username || 'User'}
              email={user?.email || 'user@example.com'}
            />

            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
              <StatCard
                title="Account Status"
                value={user?.is_verified ? 'Verified' : 'Unverified'}
                icon={Shield}
              />
              <StatCard
                title="Join Date"
                value={user?.created_at ? new Date(user.created_at).toLocaleDateString() : 'N/A'}
                icon={Calendar}
              />
              <StatCard
                title="Trusted Devices"
                value={devices.length}
                icon={Tablet}
              />
            </div>

            {/* Main Content Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <ProfileSection title="Account Details" icon={User}>
                <div className="space-y-3">
                  <p><span className="text-gray-400">Username:</span> {user?.username || 'N/A'}</p>
                  <p><span className="text-gray-400">Email:</span> {user?.email || 'N/A'}</p>
                  <p><span className="text-gray-400">Status:</span> {user?.is_verified ? 'Verified' : 'Unverified'}</p>
                </div>
              </ProfileSection>

              <ProfileSection title="Projects" icon={BookOpen}>
                {projects.length > 0 ? (
                  <div className="space-y-4">
                    {projects.map((project, index) => (
                      <div 
                        key={index}
                        className="p-3 bg-[#2a2a2a] rounded-lg transition-all duration-300 hover:bg-[#3a3a3a]"
                      >
                        <p className="font-medium">{project.name}</p>
                        <p className="text-sm text-gray-400">{project.description}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-gray-400 py-4">
                    No projects created yet
                  </div>
                )}
              </ProfileSection>

              <ProfileSection title="Shared Threads" icon={Share2}>
                {shared_threads.length > 0 ? (
                  <div className="space-y-4">
                    {shared_threads.map((thread, index) => (
                      <div 
                        key={index}
                        className="p-3 bg-[#2a2a2a] rounded-lg transition-all duration-300 hover:bg-[#3a3a3a]"
                      >
                        <p className="font-medium">{thread.name}</p>
                        <p className="text-sm text-gray-400">
                          Shared: {new Date(thread.created_at).toLocaleDateString()}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-gray-400 py-4">
                    No shared threads yet
                  </div>
                )}
              </ProfileSection>

              <ProfileSection title="Trusted Devices" icon={Tablet}>
                {devices.length > 0 ? (
                  <div className="space-y-4">
                    {devices.map((device, index) => (
                      <div 
                        key={index}
                        className="p-3 bg-[#2a2a2a] rounded-lg transition-all duration-300 hover:bg-[#3a3a3a]"
                      >
                        <p className="font-medium">{device.device_name || 'Unknown Device'}</p>
                        <p className="text-sm text-gray-400">
                          Last used: {new Date(device.last_used).toLocaleString()}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-gray-400 py-4">
                    No trusted devices
                  </div>
                )}
              </ProfileSection>
            </div>

            {/* Logout Button */}
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => setIsLogoutModalOpen(true)}
                className="flex items-center space-x-2 px-4 py-2 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors duration-200"
              >
                <LogOut className="w-5 h-5" />
                <span>Log Out</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Profile;
