import React from 'react';
import { AlertTriangle } from 'lucide-react';

const ErrorBoundary = ({ 
  error, 
  onRetry, 
  children 
}) => {
  // If used as a wrapper and children are passed
  if (children) {
    // Implement full error boundary logic here if needed
    return children;
  }

  // Standalone error display
  return (
    <div className="min-h-screen bg-[#1a1a1a] flex items-center justify-center p-4">
      <div className="bg-[#2a2a2a] rounded-xl p-8 text-center max-w-md">
        <div className="mb-6 flex justify-center">
          <AlertTriangle className="text-red-500 w-16 h-16" />
        </div>
        <h2 className="text-2xl text-gray-300 mb-4">Something Went Wrong</h2>
        <p className="text-gray-400 mb-6">
          {error || 'An unexpected error occurred'}
        </p>
        
        <div className="flex justify-center space-x-4">
          {onRetry && (
            <button 
              onClick={onRetry}
              className="px-4 py-2 bg-orange-500/10 text-orange-500 rounded hover:bg-orange-500/20"
            >
              Try Again
            </button>
          )}
          
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-gray-500/10 text-gray-300 rounded hover:bg-gray-500/20"
          >
            Reload Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
